import React from 'react';
import { Field } from 'formik';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

const DateField = ({ name, label, touched, errors }) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <Field name={name}>
      {({ field, form }) => (
        <DatePicker
          label={label}
          value={field.value ? moment(field.value, 'MM/DD/YYYY') : null}
          onChange={(date) => {
            form.setFieldValue(name, date ? date.format('MM/DD/YYYY') : '');
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              error={touched[name] && Boolean(errors[name])}
              helperText={touched[name] && errors[name]}
            />
          )}
          inputFormat="MM/DD/YYYY"
        />
      )}
    </Field>
  </LocalizationProvider>
);

export default DateField;
import axiosInstance from "./interceptor";

/**
 * 
 * @param {string} eventId - id of the event
 * @returns 
 */
export async function getEvent(eventId) {
  try {
    const result = await axiosInstance.get(`v1/admin/event/get/${eventId}`);
    return result.data;
  } catch (error) {
    return error;
  }
}

/**
 * 
 * @param {Object} data -> Event data
 * @returns 
 */
export async function updateEvent(data) {
  try {
    const result = await axiosInstance.patch(`v1/admin/event/update`, data);
    return result.data;
  } catch (error) {
    return error;
  }
}


export async function getTicketsOfAnEvent(eventId) {
  try {
    const result = await axiosInstance.get(`v1/admin/ticket?event_id=${eventId}`);
    return result.data;
  } catch (error) {
    return error;
  }
}
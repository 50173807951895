import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField, Button, InputLabel, Tooltip } from "@mui/material";
import Header from "../header/Header";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import axios from "axios";
import { toast } from "react-toastify";
import useToast from "../../hooks/useToast";
import { phone } from "phone";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const EditAdditionalInformation = () => {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const token = localStorage.getItem("token");
  const [additionalDetails, setAdditionalDetails] = useState(null);
  const toastOptions = useToast();
  const [isUpdated, setIsUpdated] = useState(true);

  const initialValues = additionalDetails
    ? {
        event_id: eventId,
        accessibility_info: additionalDetails.accessibility_info || "",
        health_safety_guidelines:
          additionalDetails.health_safety_guidelines || "",
        food_beverage_options: additionalDetails.food_beverage_options || "",
        parking_info: additionalDetails.parking_info || "",
        public_transportation: additionalDetails.public_transportation || "",
        merchandise_info: additionalDetails.merchandise_info || "",
        volunteer_opportunities:
          additionalDetails.volunteer_opportunities || "",
        contact_info: additionalDetails.contact_info || "",
      }
    : {
        event_id: eventId,
        accessibility_info: "",
        health_safety_guidelines: "",
        food_beverage_options: "",
        parking_info: "",
        public_transportation: "",
        merchandise_info: "",
        volunteer_opportunities: "",
        contact_info: "",
      };
  const phoneNumberRegex = /^[+]?[1-9]\d{1,14}$/;

  const validationSchema = Yup.object({
    accessibility_info: Yup.string(),
    health_safety_guidelines: Yup.string(),
    food_beverage_options: Yup.string(),
    parking_info: Yup.string(),
    public_transportation: Yup.string(),
    merchandise_info: Yup.string(),
    volunteer_opportunities: Yup.string(),
    contact_info: Yup.string().matches(
      phoneNumberRegex,
      "Invalid phone number format"
    ),
  });

  const handlePhoneChange = (value, country, setFieldValue) => {
    setFieldValue("contact_info", value);
    // initialValues.contact_info = value;
    // initialValues.country_code = country.dialCode;
  };

  const handleSubmit = async (values) => {
    try {
      const url = additionalDetails
        ? `${process.env.REACT_APP_API_URL}/v1/admin/additional/update`
        : `${process.env.REACT_APP_API_URL}/v1/admin/additional/save`;

      const method = additionalDetails ? "patch" : "post";

      const response = await axios({
        method: method,
        url: url,
        data: values,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      toast.success(response?.data?.message, toastOptions);
      setIsUpdated(!isUpdated);
    } catch (error) {
      toast.error(
        `Error ${additionalDetails ? "updating" : "creating"} Lineup`,
        toastOptions
      );
    }
  };

  useEffect(() => {
    const fetchAdditionalDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/admin/additional/${eventId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAdditionalDetails(response.data.data.additionalInformation);
      } catch (error) {
        console.error("Error fetching Ticket details:", error);
      }
    };

    fetchAdditionalDetails();
  }, [token, isUpdated]);

  return (
    <>
      <Header />
      <div className="container">
        <h2 className="mt-4">Additional Information</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit} className="form">
              <div className="row">
                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="accessibility_info">
                    Accessibility Information
                    <Tooltip title="Details on how the event accommodates people with disabilities">
                      <InfoIcon
                        style={{ marginLeft: "8px", fontSize: "16px" }}
                      />
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    id="accessibility_info"
                    name="accessibility_info"
                    variant="outlined"
                    fullWidth
                    value={values.accessibility_info}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.accessibility_info && !!errors.accessibility_info
                    }
                    helperText={
                      touched.accessibility_info && errors.accessibility_info
                    }
                  />
                </div>

                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="health_safety_guidelines">
                    Health and Safety Guidelines
                    <Tooltip title="COVID-19 protocols, first aid stations, security measures">
                      <InfoIcon
                        style={{ marginLeft: "8px", fontSize: "16px" }}
                      />
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    id="health_safety_guidelines"
                    name="health_safety_guidelines"
                    variant="outlined"
                    fullWidth
                    value={values.health_safety_guidelines}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.health_safety_guidelines &&
                      !!errors.health_safety_guidelines
                    }
                    helperText={
                      touched.health_safety_guidelines &&
                      errors.health_safety_guidelines
                    }
                  />
                </div>

                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="food_beverage_options">
                    Food and Beverage Options
                    <Tooltip title="Information on vendors, types of food and drinks available">
                      <InfoIcon
                        style={{ marginLeft: "8px", fontSize: "16px" }}
                      />
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    id="food_beverage_options"
                    name="food_beverage_options"
                    variant="outlined"
                    fullWidth
                    value={values.food_beverage_options}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.food_beverage_options &&
                      !!errors.food_beverage_options
                    }
                    helperText={
                      touched.food_beverage_options &&
                      errors.food_beverage_options
                    }
                  />
                </div>

                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="parking_info">
                    Parking Information
                    <Tooltip title="Details on parking availability, costs, and locations">
                      <InfoIcon
                        style={{ marginLeft: "8px", fontSize: "16px" }}
                      />
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    id="parking_info"
                    name="parking_info"
                    variant="outlined"
                    fullWidth
                    value={values.parking_info}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.parking_info && !!errors.parking_info}
                    helperText={touched.parking_info && errors.parking_info}
                  />
                </div>

                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="public_transportation">
                    Public Transportation Options
                    <Tooltip title="Information on nearby public transport and shuttle services">
                      <InfoIcon
                        style={{ marginLeft: "8px", fontSize: "16px" }}
                      />
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    id="public_transportation"
                    name="public_transportation"
                    variant="outlined"
                    fullWidth
                    value={values.public_transportation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.public_transportation &&
                      !!errors.public_transportation
                    }
                    helperText={
                      touched.public_transportation &&
                      errors.public_transportation
                    }
                  />
                </div>

                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="merchandise_info">
                    Merchandise Information
                    <Tooltip title="Details on event merchandise and where it can be purchased">
                      <InfoIcon
                        style={{ marginLeft: "8px", fontSize: "16px" }}
                      />
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    id="merchandise_info"
                    name="merchandise_info"
                    variant="outlined"
                    fullWidth
                    value={values.merchandise_info}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.merchandise_info && !!errors.merchandise_info
                    }
                    helperText={
                      touched.merchandise_info && errors.merchandise_info
                    }
                  />
                </div>

                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="volunteer_opportunities">
                    Volunteer Opportunities
                    <Tooltip title="Information on how people can volunteer and what roles are available">
                      <InfoIcon
                        style={{ marginLeft: "8px", fontSize: "16px" }}
                      />
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    id="volunteer_opportunities"
                    name="volunteer_opportunities"
                    variant="outlined"
                    fullWidth
                    value={values.volunteer_opportunities}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.volunteer_opportunities &&
                      !!errors.volunteer_opportunities
                    }
                    helperText={
                      touched.volunteer_opportunities &&
                      errors.volunteer_opportunities
                    }
                  />
                </div>

                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="contact_info">
                    Contact Information
                    <Tooltip title="For event inquiries, support, and more">
                      <InfoIcon
                        style={{ marginLeft: "8px", fontSize: "16px" }}
                      />
                    </Tooltip>
                  </InputLabel>
                  <PhoneInput
                    name="contact_info"
                    // country={values.country_code || "us"}
                    value={values.contact_info}
                    onChange={(value, country) =>
                      handlePhoneChange(value, country, setFieldValue)
                    }
                    inputStyle={{
                      width: "100%",
                      height: "40px",
                    }}
                  />
                  {touched.contact_info && errors.contact_info && (
                    <small className="text-danger">{errors.contact_info}</small>
                  )}
                </div>

                <div className="submit-div mt-5 d-flex">
                  <Button type="submit" className="btn submit">
                    Update
                  </Button>
                  <div>
                    <Button
                      type="reset"
                      className="btn cancel"
                      style={{ marginRight: "10px" }}
                      onClick={() =>
                        navigate(`/edit-ticket-information/${eventId}`)
                      }
                    >
                      Back
                    </Button>
                    <Button
                      type="reset"
                      className="btn cancel"
                      style={{ marginRight: "10px" }}
                      onClick={() => navigate(`/events`)}
                    >
                      Home
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EditAdditionalInformation;

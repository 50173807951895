
export const eventTypes = [
  { value: 'festival', label: 'Festival' },
  { value: 'concert', label: 'Concert' },
];


export const attendanceModes = [
  { value: 'online', label: 'Online' },
  { value: 'offline', label: 'Offline' },
];

export const offerCamping = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const isIndoorEvent = [
  { value: 'Indoor', label: 'Indoor' },
  { value: 'Outdoor', label: 'Outdoor' },
];

export const DEBOUNCE_TIME = 500;

export const TICKET_TYPES = {
  ga: "General Addmission",
  vip: "VIP",
  platinum: "Platinum"
}

export const headlinerType = [
  { value: 'headliner', label: 'Headliner' },
  { value: 'performer', label: 'Performer' },
]


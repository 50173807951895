import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  InputLabel,
  Tooltip,
  IconButton,
} from "@mui/material";
import Header from "../header/Header";
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import ClearIcon from "@mui/icons-material/Clear";
import { toast } from "react-toastify";
import useToast from "../../hooks/useToast";
import axios from "axios";

const MarketingInformation = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const toastOptions = useToast();
  const token = localStorage.getItem("token");
  const [marketingDetails, setMarketingDetails] = useState(null);
  const marketingId = localStorage.getItem("marketingId");
  const [fileList, setFileList] = useState([]);

  const initialValues = marketingDetails
    ? {
        event_id: eventId,
        promotional_material: marketingDetails?.promotional_material || [],
        event_hashtags: marketingDetails?.event_hashtags || "",
        press_release: marketingDetails?.press_release || "",
      }
    : {
        event_id: eventId,
        promotional_material: [],
        event_hashtags: "",
        press_release: "",
      };

  const validationSchema = Yup.object({
    promotional_material: Yup.array()
      .test(
        "hasImages",
        "At least one promotional image is required",
        (value) => {
          if (!value || value.length === 0) return false; // No files, validation fails
          const imageFiles = value.filter((file) =>
            file.type.includes("image")
          );
          return imageFiles.length > 0;
        }
      )
      .test("fileSize", "File too large", (value, context) => {
        if (!value || value.length === 0) return true; // No files, no validation needed
        const existingFileNames = (
          context.parent.promotional_material || []
        ).map((file) => file.name);
        return value.every(
          (file) =>
            existingFileNames.includes(file.name) || file.size <= 5000000 // 5MB limit
        );
      })
      .test("fileType", "Unsupported file format", (value, context) => {
        if (!value || value.length === 0) return true; // No files, no validation needed
        const existingFileNames = (
          context.parent.promotional_material || []
        ).map((file) => file.name);
        return value.every(
          (file) =>
            existingFileNames.includes(file.name) ||
            ["image/jpeg", "image/png", "video/mp4"].includes(file.type)
        );
      })
      .required("Promotional material is required"),
    event_hashtags: Yup.string(),
    press_release: Yup.string(),
  });

  const handleSubmit = async (values) => {
    try {
      const url = marketingDetails
        ? `${process.env.REACT_APP_API_URL}/v1/admin/marketing/update`
        : `${process.env.REACT_APP_API_URL}/v1/admin/marketing/save`;

      const method = marketingDetails ? "patch" : "post";
      const formData = new FormData();
      formData.append("event_id", values.event_id);

      if (method === "patch" && marketingDetails) {
        formData.append("_id", marketingId);
      }

      // Include existing promotional materials from the state
      if (values?.promotional_material?.length > 0) {
        values.promotional_material.forEach((file) => {
          formData.append(
            "existing_promotional_material[]",
            JSON.stringify(file)
          );
        });
      }

      // Handle new files
      if (
        values.promotional_material &&
        values.promotional_material.length > 0
      ) {
        values.promotional_material.forEach((file) => {
          if (file instanceof File) {
            formData.append("promotional_material", file);
          }
        });
      }

      if (values.event_hashtags) {
        formData.append("event_hashtags", values.event_hashtags);
      }
      if (values.press_release) {
        formData.append("press_release", values.press_release);
      }

      const response = await axios({
        method: method,
        url: url,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const newMarketingId = response?.data?.data?.marketing?._id;
      if (newMarketingId) {
        localStorage.setItem("marketingId", newMarketingId);
      }

      toast.success(response?.data?.message, toastOptions);
      setTimeout(() => {
        navigate(`/event-information/${eventId}`);
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error(
        `Error ${
          marketingDetails ? "updating" : "creating"
        } Marketing Information`,
        toastOptions
      );
    }
  };

  useEffect(() => {
    const fetchMarketingDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/admin/marketing/${eventId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMarketingDetails(response.data.data.marketing);
      } catch (error) {
        console.error("Error fetching marketing details:", error);
      }
    };

    fetchMarketingDetails();
  }, [token, marketingId]);

  return (
    <>
      <Header />
      <div className="container">
        <h2 className="mt-4">Marketing Information</h2>
        <Formik
          initialValues={marketingDetails || initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-6 pb-4">
                  <InputLabel htmlFor="promotional_material">
                    Promotional Images
                    <Tooltip title="Upload promotional materials images">
                      <InfoIcon
                        style={{ marginLeft: "8px", fontSize: "16px" }}
                      />
                    </Tooltip>
                  </InputLabel>
                  <Button variant="outlined" component="label" fullWidth>
                    Choose Files
                    <input
                      id="promotional_material"
                      name="promotional_material"
                      type="file"
                      multiple
                      hidden
                      accept="image/*"
                      onChange={(event) => {
                        const files = Array.from(event.target.files);
                        setFieldValue("promotional_material", [
                          ...values.promotional_material,
                          ...files,
                        ]);
                      }}
                    />
                  </Button>
                  {touched.promotional_material &&
                    errors.promotional_material && (
                      <div className="error">{errors.promotional_material}</div>
                    )}
                  <div className="mt-2">
                    {values.promotional_material.length > 0
                      ? values.promotional_material.map((file, index) => {
                          return file?.type?.includes("image") ? (
                            <div key={index} className="file-info">
                              <span>{file.name}</span>
                              <IconButton
                                onClick={() => {
                                  // Remove file from the array
                                  const updatedFiles =
                                    values.promotional_material.filter(
                                      (_, i) => i !== index
                                    );
                                  setFieldValue(
                                    "promotional_material",
                                    updatedFiles
                                  );
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </div>
                          ) : null;
                        })
                      : "No promotional images selected"}
                  </div>
                </div>

                <div className="col-6 pb-4">
                  <InputLabel htmlFor="promotional_material">
                    Promotional Video
                    <Tooltip title="Upload promotional materials videos">
                      <InfoIcon
                        style={{ marginLeft: "8px", fontSize: "16px" }}
                      />
                    </Tooltip>
                  </InputLabel>
                  <Button variant="outlined" component="label" fullWidth>
                    Choose Files
                    <input
                      id="promotional_material"
                      name="promotional_material"
                      type="file"
                      multiple
                      hidden
                      accept="video/*"
                      onChange={(event) => {
                        const files = Array.from(event.target.files);
                        setFieldValue("promotional_material", [
                          ...values.promotional_material,
                          ...files,
                        ]);
                      }}
                    />
                  </Button>
                      
                  <div className="mt-2">
                    {values.promotional_material.length > 0
                      ? values.promotional_material.map((file, index) => {
                          return file?.type?.includes("video") ? (
                            <div key={index} className="file-info">
                              <span>{file.name}</span>
                              <IconButton
                                onClick={() => {
                                  // Remove file from the array
                                  const updatedFiles =
                                    values.promotional_material.filter(
                                      (_, i) => i !== index
                                    );
                                  setFieldValue(
                                    "promotional_material",
                                    updatedFiles
                                  );
                                }}
                              >
                                <ClearIcon />
                              </IconButton>
                            </div>
                          ) : null;
                        })
                      : "No promotional videos selected"}
                  </div>
                </div>

                <div className="col-12 pb-4">
                  <TextField
                    id="event_hashtags"
                    name="event_hashtags"
                    label="Event Hashtags"
                    variant="outlined"
                    fullWidth
                    value={values.event_hashtags}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.event_hashtags && !!errors.event_hashtags}
                    helperText={touched.event_hashtags && errors.event_hashtags}
                  />
                </div>

                <div className="col-12 pb-4">
                  <TextField
                    id="press_release"
                    name="press_release"
                    label="Press Release"
                    variant="outlined"
                    fullWidth
                    value={values.press_release}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.press_release && !!errors.press_release}
                    helperText={touched.press_release && errors.press_release}
                  />
                </div>
              </div>
              <div className="col-12 d-flex justify-content-end mt-4">
                <Button
                  variant="outlined"
                  color="secondary"
                  type="button"
                  className="btn cancel"
                  onClick={() => {
                    navigate(`/lineup-information/${eventId}`);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="ml-2 btn submit"
                >
                  Save & Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default MarketingInformation;

import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Button,
  FormHelperText,
  Select,
  InputLabel,
  FormControl,
  Chip,
  Box,
  Tooltip,
  Grid,
} from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import { useFormik } from "formik";
import { eventSchema } from "../../helpers/validator";
import { toast } from "react-toastify";
import useToast from "../../hooks/useToast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../header/Header";
import axios from "axios";
import { formatEventData } from "../../utils/formatUtils";
import {
  eventTypes,
  attendanceModes,
  offerCamping,
  isIndoorEvent,
} from "../../helpers/eventHelper";
import { getEvent, updateEvent } from "../../api/api";
import { convertTo24HourFormat } from "../../utils/formatUtils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DateFieldFormik from "../../helpers/DateFieldFormik";
import InfoIcon from "@mui/icons-material/Info";

const EventForm = () => {
  const { eventId } = useParams();
  const token = localStorage.getItem("token");
  const toastOptions = useToast();
  const navigate = useNavigate();
  const [genreTypes, setGenreTypes] = useState([]);
  const [nonTraditionalGenre, setNonTraditionalGenre] = useState([]);
  const [event_times, setEventTimes] = useState([
    { day: "", timeFrom: "", timeTo: "" },
  ]);

  const initialValues = {
    event_name: "",
    event_type: "",
    event_website_url: "",
    event_start_date: "",
    event_end_date: "",
    event_venue_name: "",
    event_venue_city: "",
    event_venue_state: "",
    event_venue_country: "",
    event_venue_zip_code: "",
    latitude: "",
    longitude: "",
    event_genre_type: [],
    non_traditional_event_genre_type: [],
    is_offer_camping: "",
    is_indoor_event: "",
    event_spotify_playlist: "",
    event_apple_playlist: "",
    event_facebook_link: "",
    event_instagram_link: "",
    event_twitter_link: "",
    event_linkedin_link: "",
    about: "",
    event_times: [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: eventSchema,
    onSubmit: async (values) => {
      const formattedData = formatEventData(values);

      const result = await updateEvent({ ...formattedData, _id: eventId });

      if (result.statusCode === 200) {
        toast.success(result.message);
      } else {
        toast.error(result.error);
      }
    },
  });

  useEffect(() => {
    const getEventData = async () => {
      const result = await getEvent(eventId);

      if (result.statusCode === 200) {
        formik.setValues({
          ...result.data,
          event_start_date: result.data.event_start_date.split("T")[0],
          event_end_date: result.data.event_end_date?.split("T")[0],
          event_venue_name: result.data.event_venue?.name,
          event_venue_city: result.data.event_venue?.city,
          event_venue_state: result.data.event_venue?.state,
          event_venue_zip_code: result.data.event_venue?.zip_code,
          event_venue_country: result.data.event_venue?.country,
          latitude: result.data.event_venue?.geoLocation?.coordinates[1],
          longitude: result.data.event_venue?.geoLocation?.coordinates[0],
          event_genre_type: result.data.event_genre_type,
          event_spotify_playlist: result.data.event_spotify_playlist,
          event_apple_playlist: result.data.event_apple_playlist,
          event_facebook_link: result.data.event_social_media?.facebook,
          event_instagram_link: result.data.event_social_media?.instagram,
          event_twitter_link: result.data.event_social_media?.twitter,
          event_linkedin_link: result.data.event_social_media?.linkedin,
          event_times: result.data.event_times?.map((eventTime) => {
            return {
              day: eventTime.day || "",
              timeFrom: eventTime.timeFrom || "",
              timeTo: eventTime.timeTo || "",
            };
          }),
        });

        const allEventTimes = result.data.event_times?.map((eventTime) => {
          return {
            day: eventTime.day || "",
            timeFrom: eventTime.timeFrom || "",
            timeTo: eventTime.timeTo || "",
          };
        });
        setEventTimes(allEventTimes);
      }
    };

    if (eventId) {
      getEventData();
    }
  }, [eventId]);

  useEffect(() => {
    const fetchGenreTypes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/admin/genere`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sortedGenres = response.data.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setGenreTypes(sortedGenres);
      } catch (error) {
        console.error("Failed to fetch genre types:", error);
      }
    };

    fetchGenreTypes();
  }, []);

  useEffect(() => {
    const fetchNonTraditionalGenre = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/admin/nonTradGenere`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sortedNontraditionalGenres = response.data.data.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setNonTraditionalGenre(sortedNontraditionalGenres);
      } catch (error) {
        console.error("Failed to fetch genre types:", error);
      }
    };

    fetchNonTraditionalGenre();
  }, []);

  const [autocompleteValue, setAutocompleteValue] = useState(
    initialValues.event_venue_name
  );

  useEffect(() => {
    setAutocompleteValue(initialValues.event_venue_name);
  }, [initialValues.event_venue_name]);

  const handlePlace = (place) => {
    const addressComponents = place?.address_components;
    const latitude = place?.geometry?.location.lat() || "";
    const longitude = place?.geometry?.location.lng() || "";

    const city =
      addressComponents?.find((component) =>
        component?.types?.includes("locality")
      )?.long_name || "";
    const state =
      addressComponents?.find((component) =>
        component?.types?.includes("administrative_area_level_1")
      )?.long_name || "";
    const country =
      addressComponents?.find((component) =>
        component?.types?.includes("country")
      )?.long_name || "";

    formik.setFieldValue("event_venue_name", place.formatted_address || "");
    formik.setFieldValue("event_venue_city", city || "");
    formik.setFieldValue("event_venue_state", state || "");
    formik.setFieldValue("event_venue_country", country || "");
    formik.setFieldValue("latitude", latitude || "");
    formik.setFieldValue("longitude", longitude || "");
  };

  const handleAddEventTime = () => {
    setEventTimes([...event_times, { day: "", timeFrom: "", timeTo: "" }]);
  };

  const handleRemoveEventTime = (index) => {
    const newEventTimes = [...event_times];
    newEventTimes.splice(index, 1);
    setEventTimes(newEventTimes);
    formik.setFieldValue("event_times", newEventTimes);
  };

  return (
    <>
      <Header />
      <form onSubmit={formik.handleSubmit} className="form">
        <div className="row">
          <div className="col-md-6 pb-4">
            <TextField
              name="event_name"
              label="Event Name"
              variant="outlined"
              fullWidth
              value={formik.values.event_name}
              onChange={formik.handleChange}
              error={formik.touched.event_name && !!formik.errors.event_name}
              helperText={formik.touched.event_name && formik.errors.event_name}
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_type"
              label="Event Type"
              variant="outlined"
              select
              fullWidth
              value={formik.values.event_type}
              onChange={formik.handleChange}
              error={formik.touched.event_type && !!formik.errors.event_type}
              helperText={formik.touched.event_type && formik.errors.event_type}
            >
              {eventTypes?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="col-md-6 pb-4">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateFieldFormik
                name="event_start_date"
                label="Event Start Date"
                value={formik.values.event_start_date}
                onChange={formik.setFieldValue}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.event_start_date &&
                  Boolean(formik.errors.event_start_date)
                }
                helperText={
                  formik.touched.event_start_date &&
                  formik.errors.event_start_date
                }
              />
            </LocalizationProvider>
          </div>

          <div className="col-md-6 pb-4">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateFieldFormik
                name="event_end_date"
                label="Event End Date"
                value={formik.values.event_end_date}
                onChange={formik.setFieldValue}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.event_end_date &&
                  Boolean(formik.errors.event_end_date)
                }
                helperText={
                  formik.touched.event_end_date && formik.errors.event_end_date
                }
              />
            </LocalizationProvider>
          </div>

          {event_times?.map((eventTime, index) => (
            <div key={index} className="col-12 pb-4">
              <InputLabel
                htmlFor={`event${index}_day`}
                style={{ display: "flex", alignItems: "center" }}
              >
                Time of Event
                <Tooltip title="Select the day and time for Event">
                  <InfoIcon style={{ marginLeft: "8px", fontSize: "20px" }} />
                </Tooltip>
              </InputLabel>
              <TextField
                id={`event${index}_day`}
                name={`event${index}_day`}
                label={`Day ${index + 1}`}
                variant="outlined"
                fullWidth
                value={event_times[index].day}
                onChange={(e) => {
                  const newEventTimes = [...event_times];
                  newEventTimes[index].day = e.target.value;
                  setEventTimes(newEventTimes);
                  formik.setFieldValue("event_times", newEventTimes);
                }}
                error={
                  formik.touched[`event${index}_day`] &&
                  !!formik.errors[`event${index}_day`]
                }
                helperText={
                  formik.touched[`event${index}_day`] &&
                  formik.errors[`event${index}_day`]
                }
              />
              <Grid container spacing={2} className="mt-2">
                <Grid item xs={6}>
                  <TextField
                    id={`event${index}_time_from`}
                    name={`event${index}_time_from`}
                    label="From"
                    type="time"
                    variant="outlined"
                    fullWidth
                    value={event_times[index].timeFrom}
                    onChange={(e) => {
                      const newEventTimes = [...event_times];
                      newEventTimes[index].timeFrom = e.target.value;
                      setEventTimes(newEventTimes);
                    }}
                    error={
                      formik.touched[`event${index}_time_from`] &&
                      !!formik.errors[`event${index}_time_from`]
                    }
                    helperText={
                      formik.touched[`event${index}_time_from`] &&
                      formik.errors[`event${index}_time_from`]
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id={`event${index}_time_to`}
                    name={`event${index}_time_to`}
                    label="To"
                    type="time"
                    variant="outlined"
                    fullWidth
                    value={eventTime.timeTo}
                    onChange={(e) => {
                      const newEventTimes = [...event_times];
                      newEventTimes[index].timeTo = e.target.value;
                      setEventTimes(newEventTimes);
                    }}
                    error={
                      formik.touched[`event${index}_time_to`] &&
                      !!formik.errors[`event${index}_time_to`]
                    }
                    helperText={
                      formik.touched[`event${index}_time_to`] &&
                      formik.errors[`event${index}_time_to`]
                    }
                  />
                </Grid>
              </Grid>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleRemoveEventTime(index)}
                className="mt-2"
              >
                Remove
              </Button>
            </div>
          ))}

          <div className="col-12 pb-4">
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEventTime}
            >
              Add Time of Event
            </Button>
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_website_url"
              label="Event Website URL"
              variant="outlined"
              fullWidth
              value={formik.values.event_website_url}
              onChange={formik.handleChange}
              error={
                formik.touched.event_website_url &&
                !!formik.errors.event_website_url
              }
              helperText={
                formik.touched.event_website_url &&
                formik.errors.event_website_url
              }
            />
          </div>

          <div className="col-md-6 pb-4">
            <Autocomplete
              apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
              onPlaceSelected={(place) =>
                handlePlace(place, formik.setFieldValue)
              }
              name="event_venue_name"
              value={formik.values.event_venue_name}
              onChange={(e) => {
                formik.setFieldValue("event_venue_name", e.target.value);
                setAutocompleteValue(e.target.value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Venue Name"
                  variant="outlined"
                  fullWidth
                  error={
                    formik.touched.event_venue_name &&
                    !!formik.errors.event_venue_name
                  }
                  helperText={
                    formik.touched.event_venue_name &&
                    formik.errors.event_venue_name
                  }
                />
              )}
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_venue_city"
              label="City"
              variant="outlined"
              fullWidth
              value={formik.values.event_venue_city}
              onChange={formik.handleChange}
              error={
                formik.touched.event_venue_city &&
                !!formik.errors.event_venue_city
              }
              helperText={
                formik.touched.event_venue_city &&
                formik.errors.event_venue_city
              }
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_venue_state"
              label="State"
              variant="outlined"
              fullWidth
              value={formik.values.event_venue_state}
              onChange={formik.handleChange}
              error={
                formik.touched.event_venue_state &&
                !!formik.errors.event_venue_state
              }
              helperText={
                formik.touched.event_venue_state &&
                formik.errors.event_venue_state
              }
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_venue_country"
              label="Country"
              variant="outlined"
              fullWidth
              value={formik.values.event_venue_country}
              onChange={formik.handleChange}
              error={
                formik.touched.event_venue_country &&
                !!formik.errors.event_venue_country
              }
              helperText={
                formik.touched.event_venue_country &&
                formik.errors.event_venue_country
              }
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_venue_zip_code"
              label="Zip Code"
              variant="outlined"
              fullWidth
              value={formik.values.event_venue_zip_code}
              onChange={formik.handleChange}
              error={
                formik.touched.event_venue_zip_code &&
                !!formik.errors.event_venue_zip_code
              }
              helperText={
                formik.touched.event_venue_zip_code &&
                formik.errors.event_venue_zip_code
              }
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="latitude"
              label="Latitude"
              variant="outlined"
              fullWidth
              value={formik.values.latitude}
              onChange={formik.handleChange}
              error={formik.touched.latitude && !!formik.errors.latitude}
              helperText={formik.touched.latitude && formik.errors.latitude}
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="longitude"
              label="Longitude"
              variant="outlined"
              fullWidth
              value={formik.values.longitude}
              onChange={formik.handleChange}
              error={formik.touched.longitude && !!formik.errors.longitude}
              helperText={formik.touched.longitude && formik.errors.longitude}
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              select
              fullWidth
              label="Genre Type"
              variant="outlined"
              name="event_genre_type"
              value={formik.values.event_genre_type}
              onChange={formik.handleChange}
              error={
                formik.touched.event_genre_type &&
                !!formik.errors.event_genre_type
              }
              helperText={
                formik.touched.event_genre_type &&
                formik.errors.event_genre_type
              }
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected?.map((value) => (
                      <Chip
                        key={value}
                        label={
                          genreTypes.find((genre) => genre._id === value)?.name
                        }
                        onDelete={() => {
                          const newValues =
                            formik.values.event_genre_type.filter(
                              (id) => id !== value
                            );
                          formik.setFieldValue("event_genre_type", newValues);
                        }}
                      />
                    ))}
                  </Box>
                ),
              }}
            >
              {genreTypes?.map((genre) => (
                <MenuItem key={genre._id} value={genre._id}>
                  {genre.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              select
              fullWidth
              label="Non-Traditional Genre Type"
              variant="outlined"
              name="non_traditional_event_genre_type"
              value={formik.values.non_traditional_event_genre_type}
              onChange={formik.handleChange}
              error={
                formik.touched.non_traditional_event_genre_type &&
                !!formik.errors.non_traditional_event_genre_type
              }
              helperText={
                formik.touched.non_traditional_event_genre_type &&
                formik.errors.non_traditional_event_genre_type
              }
              SelectProps={{
                multiple: true,
                renderValue: (selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected?.map((value) => (
                      <Chip
                        key={value}
                        label={
                          nonTraditionalGenre.find(
                            (genre) => genre._id === value
                          )?.name
                        }
                        onDelete={() => {
                          const newValues =
                            formik.values.non_traditional_event_genre_type.filter(
                              (id) => id !== value
                            );
                          formik.setFieldValue(
                            "non_traditional_event_genre_type",
                            newValues
                          );
                        }}
                      />
                    ))}
                  </Box>
                ),
              }}
            >
              {nonTraditionalGenre?.map((genre) => (
                <MenuItem key={genre._id} value={genre._id}>
                  {genre.name}
                </MenuItem>
              ))}
            </TextField>
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_spotify_playlist"
              label="Event Spotify Playlist"
              variant="outlined"
              fullWidth
              value={formik.values.event_spotify_playlist}
              onChange={formik.handleChange}
              error={
                formik.touched.event_spotify_playlist &&
                !!formik.errors.event_spotify_playlist
              }
              helperText={
                formik.touched.event_spotify_playlist &&
                formik.errors.event_spotify_playlist
              }
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_apple_playlist"
              label="Event Apple Playlist"
              variant="outlined"
              fullWidth
              value={formik.values.event_apple_playlist}
              onChange={formik.handleChange}
              error={
                formik.touched.event_apple_playlist &&
                !!formik.errors.event_apple_playlist
              }
              helperText={
                formik.touched.event_apple_playlist &&
                formik.errors.event_apple_playlist
              }
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_facebook_link"
              label="Event Facebook Link"
              variant="outlined"
              fullWidth
              value={formik.values.event_facebook_link}
              onChange={formik.handleChange}
              error={
                formik.touched.event_facebook_link &&
                !!formik.errors.event_facebook_link
              }
              helperText={
                formik.touched.event_facebook_link &&
                formik.errors.event_facebook_link
              }
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_instagram_link"
              label="Event Instagram Link"
              variant="outlined"
              fullWidth
              value={formik.values.event_instagram_link}
              onChange={formik.handleChange}
              error={
                formik.touched.event_instagram_link &&
                !!formik.errors.event_instagram_link
              }
              helperText={
                formik.touched.event_instagram_link &&
                formik.errors.event_instagram_link
              }
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_twitter_link"
              label="Event Twitter Link"
              variant="outlined"
              fullWidth
              value={formik.values.event_twitter_link}
              onChange={formik.handleChange}
              error={
                formik.touched.event_twitter_link &&
                !!formik.errors.event_twitter_link
              }
              helperText={
                formik.touched.event_twitter_link &&
                formik.errors.event_twitter_link
              }
            />
          </div>

          <div className="col-md-6 pb-4">
            <TextField
              name="event_linkedin_link"
              label="Event Linkedin Link"
              variant="outlined"
              fullWidth
              value={formik.values.event_linkedin_link}
              onChange={formik.handleChange}
              error={
                formik.touched.event_linkedin_link &&
                !!formik.errors.event_linkedin_link
              }
              helperText={
                formik.touched.event_linkedin_link &&
                formik.errors.event_linkedin_link
              }
            />
          </div>

          <div className="col-md-12 pb-4">
            <TextField
              name="about"
              label="About"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={formik.values.about}
              onChange={formik.handleChange}
              error={formik.touched.about && !!formik.errors.about}
              helperText={formik.touched.about && formik.errors.about}
            />
          </div>
          <div className="submit-div mt-5 d-flex">
            <Button type="submit" className="btn submit">
              Update
            </Button>
            <div>
              <Button
                type="reset"
                className="btn cancel"
                style={{ marginRight: "10px" }}
                onClick={() => navigate(`/events`)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="btn cancel"
                onClick={() => navigate(`/edit-lineup/${eventId}`)}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default EventForm;

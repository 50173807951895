import React from "react";
import "./Form.scss"
import Header from "../header/Header";
import Input from '@mui/material/Input';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';




const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const currencies = [
    {
        value: 'Mumbai',
        label: 'Mumbai',
    },
    {
        value: 'Dehradun',
        label: 'Dehradun'
    },
    {
        value: 'Delhi',
        label: 'Delhi',
    },
    {
        value: 'Bangalore',
        label: 'Bangalore',
    },
];
const states = [
    {
        value: 'Uttar Pradesh',
        label: 'Uttar Pradesh',
    },
    {
        value: 'Tamil Nadu',
        label: 'Tamil Nadu'
    },
    {
        value: 'Delhi',
        label: 'Delhi',
    },
    {
        value: 'Maharashtra',
        label: 'Bangalore',
    },
];


function Form() {
    return <>
        <Header />
        <form action="" className="form">
            <div className="row">
                <div className="col-md-6 pb-4">
                    <TextField id="outlined-basic" label="First Name" variant="outlined" />
                </div>
                <div className="col-md-6">
                    <TextField id="outlined-basic" label="Last Name" variant="outlined" />
                </div>
                <div className="col-md-6 pb-4">
                    <TextField id="outlined-basic" label="Email" variant="outlined" />
                </div>
                <div className="col-md-6 pb-4">
                    <TextField id="outlined-basic" label="Phone Number" variant="outlined" />
                </div>
                <div className="col-12 pb-4">
                    <TextField id="outlined-basic" label="Enter Temporary Address" variant="outlined" />
                </div>

                <div className="col-12 pb-4">
                    <TextField id="outlined-basic" label="Enter Permanent Address" variant="outlined" />
                </div>
                <div className="col-12 pb-4">
                    <FormControl>
                        <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                        >
                            <FormControlLabel value="female" control={<Radio />} label="Female" />
                            <FormControlLabel value="male" control={<Radio />} label="Male" />
                            <FormControlLabel value="other" control={<Radio />} label="Other" />
                            <FormControlLabel
                                control={<Radio />}
                            />
                        </RadioGroup>
                    </FormControl>
                </div>

                <div className="col-12 pb-4">
                    <FormGroup>
                    <FormLabel id="demo-row-radio-buttons-group-label">Languages</FormLabel>
                        <FormControlLabel control={<Checkbox defaultChecked />} label="English" />
                        <FormControlLabel required control={<Checkbox />} label="Spanish" />
                        <FormControlLabel required control={<Checkbox />} label="Arabic" />
                        <FormControlLabel required control={<Checkbox />} label="French" />               
                    </FormGroup>
                </div>


                <div className="col-12 pb-4">
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-multiline-static"
                                label="Messeges"
                                multiline
                                rows={4}

                            />
                        </div>
                    </Box>
                </div>




                <div className="col-12 pb-4">
                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Select"
                                defaultValue="Mumbai"
                                helperText="Please select your city"
                            >
                                {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </Box>
                </div>


                <div className="col-12">

                    <Box
                        component="form"
                        sx={{
                            '& .MuiTextField-root': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="on"
                    >
                        <div>
                            <TextField
                                id="outlined-select-currency"
                                select
                                label="Select"
                                defaultValue="Uttar Pradesh"
                                helperText="Please select your State"
                            >
                                {states.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    </Box>
                </div>


                <div className="submit-div mt-5 d-flex">
                    <button className="btn submit">Submit</button>
                    <button className="btn cancel">Cancel</button>
                </div>


            </div>
        </form>
    </>
}
export default Form;
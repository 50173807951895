import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import useToast from "../../hooks/useToast";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ageGroups = ["18-24", "25-34", "35-44", "45-54", "55-64", "64+"];

export default function TribeModal({ open, handleClose, eventId }) {
  const toastOptions = useToast();
  const token = localStorage.getItem("token");

  const initialValues = {
    name: "",
    event_id: eventId,
    age_groups: [], // This will be set in handleSubmit
  };

  const publicTribeSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const valuesWithAgeGroups = {
      ...values,
      age_groups: ageGroups, // Set all age groups here
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/admin/public/tribe/save`,
        valuesWithAgeGroups,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Public Tribe Created Successfully", toastOptions);
      handleClose();
      resetForm();
    } catch (error) {
      const errorMessage =
        error.response?.data?.error || "Error creating public tribe";
      toast.error(errorMessage, toastOptions);
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      aria-labelledby="tribe-modal-title"
      aria-describedby="tribe-modal-description"
    >
      <Box sx={style}>
        <IconButton
          sx={{ position: "absolute", top: 8, right: 8 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography id="tribe-modal-title" variant="h6" component="h2">
          Create Public Tribe
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={publicTribeSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <Field
                as={TextField}
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}

TribeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
};

import React, { useState } from "react";
import "./Header.scss"
import { IoSearch } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { CiMenuBurger } from "react-icons/ci";
import Profile from "../../assets/images/profile.svg";
import useToast from "../../hooks/useToast";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const Header = ({ toggle }) => {
    const toastOptions = useToast();
    
    
    const handleLogout = () => {
        localStorage.clear();
        toast.success("Successfully logged out!", toastOptions);
    };

    return (

        <header>
            <div className="para">
                <h3 className="heading-text">Dashboard</h3>
            </div>
            <div >
                <div className="toggle d-xl-none d-block" onClick={toggle}>
                    <CiMenuBurger />

                </div>
            </div>
            <div className="tools">
                <div className="dropdown">
                    <div className="auth dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="profile">
                            <div className="img">
                                <img src={Profile} alt="" />
                            </div>
                            <div className="name">Admin</div>
                        </div>
                    </div>

                    <ul className="dropdown-menu mt-2 shadow" aria-labelledby="dropdownMenuButton1">
                        
                        <li><NavLink className="dropdown-item" to="/" onClick={handleLogout}>Logout</NavLink></li>
                    </ul>
                </div>

            </div>
        </header>
    )
}

export default Header;



import React from 'react'
import Header from '../header/Header';
import SuggestionTable from '../transactions/SuggestionTable';

const Suggesion = () => {
    return (
      <>
        <Header />
        
        <div className="row">
          <div className="col-12 main-table ms-2 mt-5">
            <SuggestionTable />
          </div>
        </div>
      </>
    );
}

export default Suggesion

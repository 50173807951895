import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getTicketsOfAnEvent } from "../../api/api";
import {
  TICKET_TYPES,
  offerCamping,
  isIndoorEvent,
} from "../../helpers/eventHelper";
import {
  TextField,
  Button,
  MenuItem,
  InputLabel,
  Select,
  FormHelperText,
  FormControl,
  Tooltip,
  IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import Header from "../header/Header";
import axios from "axios";
import { toast } from "react-toastify";
import useToast from "../../hooks/useToast";
import { Description } from "@mui/icons-material";

function EditTicketInformation() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [ticketInfo, setTicketInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  const toastOptions = useToast();
  const [ticketDetails, setTicketDetails] = useState(null);
  const ticketId = localStorage.getItem("ticketId");
  const [isUpdated, setIsUpdated] = useState(true);

  const TICKET_TYPES = [
    { value: "ga", label: "General Admission" },
    { value: "VIP", label: "VIP" },
    { value: "Platinum", label: "Platinum" },
    { value: "Other", label: "Other" },
  ];

  const initialValues = {
    event_id: eventId,
    is_offer_camping: "",
    is_indoor_event: "",
    ticket_types: [
      {
        type: "ga",
        price: "",
      },
    ],
    event_capacity: "",
    max_ticket_purchase: "",
    group_discount: "",
    presale_offer: "",
    premium_offers: "",
    payment_methods: "",
    refund_policy: "",
  };

  const validationSchema = Yup.object({
    is_offer_camping: Yup.string(),
    is_indoor_event: Yup.string(),
    event_capacity: Yup.number()
      .positive("Capacity must be positive")
      .nullable(),
    max_ticket_purchase: Yup.number()
      .positive("Number must be positive")
      .nullable(),
    group_discount: Yup.string(),
    presale_offer: Yup.string(),
    premium_offers: Yup.string(),
    payment_methods: Yup.string(),
    refund_policy: Yup.string(),
  });

  const handleSubmit = async (values) => {
    try {
      const payload = { ...values };
      payload.ticket_types.forEach((ticket) => {
        if (ticket.type === "Other") {
          delete ticket.price;
          delete ticket.showExtra;
        } else {
          delete ticket.description;
          delete ticket.extra_price;
          delete ticket.showExtra;
        }
      });

      const url = ticketDetails
        ? `${process.env.REACT_APP_API_URL}/v1/admin/event/ticket/update`
        : `${process.env.REACT_APP_API_URL}/v1/admin/event/ticket/save`;

      const method = ticketDetails ? "patch" : "post";

      const response = await axios({
        method: method,
        url: url,
        data: payload,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      toast.success(response?.data?.message, toastOptions);
      setIsUpdated(!isUpdated);
    } catch (error) {
      toast.error(
        `Error ${eventId ? "updating" : "creating"} Event Ticket`,
        toastOptions
      );
    }
  };

  useEffect(() => {
    const fetchEventTicketDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/admin/event/ticket/${eventId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setTicketDetails(response.data.data.ticket);
      } catch (error) {
        console.error("Error fetching Ticket details:", error);
      }
    };

    fetchEventTicketDetails();
  }, [token, ticketId, isUpdated]);

  return (
    <div>
      <Header />
      <Formik
        initialValues={ticketDetails || initialValues}
        // initialValues={ticketDetails ? { ...initialValues, ...ticketDetails } : initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};

          if (!values.ticket_types || !values.ticket_types.length) {
            errors.ticket_types = "At least one ticket type is required";
          } else {
            errors.ticket_types = values.ticket_types.map((ticket, index) => {
              const ticketErrors = {};

              if (!ticket.type) {
                ticketErrors.type = "Ticket type is required";
              } else if (
                !["ga", "VIP", "Platinum", "Other"].includes(ticket.type)
              ) {
                ticketErrors.type = "Invalid ticket type";
              }

              if (ticket.type && ticket.type !== "Other") {
                if (ticket.price === undefined || ticket.price === null) {
                } else if (ticket.price < 0) {
                  ticketErrors.price = "Price must be positive";
                }
              }

              if (ticket.type === "Other") {
                if (!ticket.description) {
                  ticketErrors.description =
                    "Description is required for 'Other' type";
                } else if (ticket.description.length < 1) {
                  ticketErrors.description =
                    "Description must be at least 1 character long";
                }

                if (
                  ticket.extra_price === undefined ||
                  ticket.extra_price === null
                ) {
                  ticketErrors.extra_price =
                    "Extra price is required for 'Other' type";
                } else if (ticket.extra_price < 0) {
                  ticketErrors.extra_price = "Extra price must be positive";
                }
              }

              return Object.keys(ticketErrors).length > 0 ? ticketErrors : null;
            });

            if (!errors.ticket_types.some(Boolean)) {
              delete errors.ticket_types;
            }
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} className="form">
            <div className="container">
              <h2 className="mt-4">Event Information</h2>
              <div className="row">
                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="is_offer_camping">
                    Does this Event Offer Camping?
                  </InputLabel>
                  <TextField
                    id="is_offer_camping"
                    name="is_offer_camping"
                    variant="outlined"
                    select
                    fullWidth
                    value={values.is_offer_camping}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.is_offer_camping && !!errors.is_offer_camping
                    }
                    helperText={
                      touched.is_offer_camping && errors.is_offer_camping
                    }
                  >
                    {offerCamping.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="is_indoor_event">
                    Is this Event Indoor or Outdoor?
                  </InputLabel>
                  <TextField
                    id="is_indoor_event"
                    name="is_indoor_event"
                    variant="outlined"
                    select
                    fullWidth
                    value={values.is_indoor_event}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.is_indoor_event && !!errors.is_indoor_event}
                    helperText={
                      touched.is_indoor_event && errors.is_indoor_event
                    }
                  >
                    {isIndoorEvent.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="col-md-12 pb-4">
                  <InputLabel htmlFor="ticket_types">
                    Ticketing Information
                  </InputLabel>
                  <FieldArray
                    name="ticket_types"
                    render={({ push, remove }) => (
                      <>
                        {values.ticket_types.map((ticket, index) => {
                          // Filter out already selected ticket types but keep 'Other' available
                          const selectedTypes = values.ticket_types.map(
                            (t) => t.type
                          );

                          const availableOptions = TICKET_TYPES.filter(
                            (option) =>
                              option.value === "Other" ||
                              !selectedTypes.includes(option.value) ||
                              selectedTypes.indexOf(option.value) === index
                          );

                          return (
                            <div
                              key={index}
                              className="d-flex align-items-center mb-2"
                            >
                              <FormControl
                                variant="outlined"
                                fullWidth
                                error={
                                  touched.ticket_types &&
                                  touched.ticket_types[index]?.type &&
                                  !!errors.ticket_types?.[index]?.type
                                }
                              >
                                <InputLabel>Ticket Type</InputLabel>
                                <Select
                                  name={`ticket_types[${index}].type`}
                                  value={ticket.type}
                                  onChange={(e) => {
                                    const value = e.target.value;
                                    setFieldValue(
                                      `ticket_types[${index}].type`,
                                      value
                                    );

                                    // Show or hide description and additional price fields based on selected type
                                    if (value === "Other") {
                                      setFieldValue(
                                        `ticket_types[${index}].showExtra`,
                                        true
                                      );
                                    } else {
                                      setFieldValue(
                                        `ticket_types[${index}].showExtra`,
                                        false
                                      );
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  label="Ticket Type"
                                >
                                  {availableOptions?.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>
                                  {touched.ticket_types &&
                                    touched.ticket_types[index]?.type &&
                                    errors.ticket_types?.[index]?.type}
                                </FormHelperText>
                              </FormControl>

                              {/* Conditionally render fields based on ticket type */}
                              {ticket?.type === "Other" ? (
                                <div
                                  className="extra-fields"
                                  style={{
                                    marginLeft: "20px",
                                    marginTop: "10px",
                                    width: "100%",
                                  }}
                                >
                                  <TextField
                                    name={`ticket_types[${index}].description`}
                                    label="Ticket Type"
                                    variant="outlined"
                                    value={ticket?.description || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                    style={{ marginBottom: "10px" }}
                                  />
                                  <TextField
                                    name={`ticket_types[${index}].extra_price`}
                                    label="Price"
                                    type="number"
                                    variant="outlined"
                                    value={ticket?.extra_price || ""}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    fullWidth
                                  />
                                </div>
                              ) : (
                                <TextField
                                  name={`ticket_types[${index}].price`}
                                  label="Price"
                                  type="number"
                                  variant="outlined"
                                  value={ticket?.price}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.ticket_types &&
                                    touched.ticket_types[index]?.price &&
                                    !!errors.ticket_types?.[index]?.price
                                  }
                                  helperText={
                                    touched.ticket_types &&
                                    touched.ticket_types[index]?.price &&
                                    errors.ticket_types?.[index]?.price
                                  }
                                  fullWidth
                                  style={{ marginLeft: "10px" }}
                                />
                              )}
                              <Button
                                type="button"
                                onClick={() => remove(index)}
                                variant="outlined"
                                color="error"
                                style={{ marginLeft: "10px" }}
                              >
                                Remove
                              </Button>
                            </div>
                          );
                        })}
                        <Button
                          type="button"
                          onClick={() =>
                            push({ type: "", price: "", showExtra: false })
                          }
                          variant="contained"
                          color="primary"
                        >
                          Add Ticket Type
                        </Button>
                      </>
                    )}
                  />
                </div>

                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="event_capacity">
                    Event Capacity
                    <Tooltip title="Enter the total number of people the event can accommodate.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    name="event_capacity"
                    label="Event Capacity"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={values.event_capacity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.event_capacity && !!errors.event_capacity}
                    helperText={touched.event_capacity && errors.event_capacity}
                  />
                </div>

                <div className="col-md-6 pb-4">
                  <InputLabel htmlFor="max_ticket_purchase">
                    Maximum Ticket Purchase per User
                    <Tooltip title="Specify the maximum number of tickets a single user can purchase.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    name="max_ticket_purchase"
                    label="Maximum Ticket Purchase per User"
                    type="number"
                    variant="outlined"
                    fullWidth
                    value={values.max_ticket_purchase}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.max_ticket_purchase &&
                      !!errors.max_ticket_purchase
                    }
                    helperText={
                      touched.max_ticket_purchase && errors.max_ticket_purchase
                    }
                  />
                </div>

                <div className="col-md-12 pb-4">
                  <InputLabel htmlFor="group_discount">
                    Group Discount
                    <Tooltip title="Provide any available group discounts for bulk ticket purchases.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    name="group_discount"
                    label="Group Discount"
                    variant="outlined"
                    fullWidth
                    value={values.group_discount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.group_discount && !!errors.group_discount}
                    helperText={touched.group_discount && errors.group_discount}
                  />
                </div>

                <div className="col-md-12 pb-4">
                  <InputLabel htmlFor="presale_offer">
                    Presale Offer
                    <Tooltip title="Describe any presale offers available.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    name="presale_offer"
                    label="Presale Offer"
                    variant="outlined"
                    fullWidth
                    value={values.presale_offer}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.presale_offer && !!errors.presale_offer}
                    helperText={touched.presale_offer && errors.presale_offer}
                  />
                </div>

                <div className="col-md-12 pb-4">
                  <InputLabel htmlFor="premium_offers">
                    Premium Offers
                    <Tooltip title="Specify any premium offers available.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    name="premium_offers"
                    label="Premium Offers"
                    variant="outlined"
                    fullWidth
                    value={values.premium_offers}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.premium_offers && !!errors.premium_offers}
                    helperText={touched.premium_offers && errors.premium_offers}
                  />
                </div>

                <div className="col-md-12 pb-4">
                  <InputLabel htmlFor="payment_methods">
                    Payment Methods
                    <Tooltip title="List the payment methods accepted for ticket purchases.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <FormControl fullWidth variant="outlined">
                    <Select
                      name="payment_methods"
                      value={values.payment_methods}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.payment_methods && !!errors.payment_methods
                      }
                      displayEmpty
                      inputProps={{ "aria-label": "Payment Methods" }}
                    >
                      <MenuItem value="" disabled>
                        Select payment method
                      </MenuItem>
                      <MenuItem value="credit_card">Credit Card</MenuItem>
                      <MenuItem value="paypal">PayPal</MenuItem>
                    </Select>
                  </FormControl>
                  {touched.payment_methods && errors.payment_methods && (
                    <div className="error-message">
                      {errors.payment_methods}
                    </div>
                  )}
                </div>

                <div className="col-md-12 pb-4">
                  <InputLabel htmlFor="refund_policy">
                    Refund Policy
                    <Tooltip title="Describe the refund policy for ticket purchases.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>
                  <TextField
                    name="refund_policy"
                    label="Refund Policy"
                    variant="outlined"
                    fullWidth
                    value={values.refund_policy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.refund_policy && !!errors.refund_policy}
                    helperText={touched.refund_policy && errors.refund_policy}
                  />
                </div>

                <div className="submit-div mt-5 d-flex">
                  <Button type="submit" className="btn submit">
                    Update
                  </Button>
                  <div>
                    <Button
                      type="reset"
                      className="btn cancel"
                      style={{ marginRight: "10px" }}
                      onClick={() => navigate(`/edit-marketing/${eventId}`)}
                    >
                      Back
                    </Button>
                    <Button
                      type="button"
                      className="btn cancel"
                      onClick={() =>
                        navigate(`/edit-additional-information/${eventId}`)
                      }
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditTicketInformation;

import React from "react";
import "./Login.scss";
import google from "../../../assets/images/google-icon.svg";
import { NavLink } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import axios from "axios";
import useToast from "../../../hooks/useToast";
import * as Yup from "yup";
import { loginSchema } from "../../../helpers/validator";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { storeToken } from "../../../redux/reducers/authSlice";

export const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const dispatch = useDispatch();

  const toastOptions = useToast();
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/auth/admin/login`,
          values
        );

          const token = response.data.data;
        localStorage.setItem("token", token);
        dispatch(storeToken(token));
        toast.success(" Successfully Login!", toastOptions);
        navigate("/dashboard");
        resetForm();
      } catch (error) {
        toast.error("Please enter correct Email and Password.", toastOptions);
      }
    },
  });

  return (
    <div className="authentication">
      <div className="container-fluid">
        <div className="row login-bg">
          <div className="profile-bg-2"></div>
          <div className="col-md-12 centerMid">
            <div className="main-form-2">
              <h1 className="text-light text-center">Welcome !</h1>
              <p className="text-light text-center mb-5">
                Use these awesome forms to login in your project.
              </p>
              <form onSubmit={formik.handleSubmit} className="authForm-2">
                <div className="register-box text-center">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="mb-4">Sign In !</h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 pb-4">
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      variant="outlined"
                      fullWidth
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error">{formik.errors.email}</div>
                    ) : null}
                  </div>
                  <div className="col-12">
                    <FormControl fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="error">{formik.errors.password}</div>
                    ) : null}
                  </div>
                  <div className="col-12 my-3"></div>
                  <div className="col-12 mb-4">
                    <button className="btn sign-up-btn" type="submit">
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;

import React from "react";
import { Formik, Field, Form, FieldArray } from "formik";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import { toast } from "react-toastify";
import useToast from '../../hooks/useToast';
import axios from 'axios';
import { addTicketSchema } from "../../helpers/validator";
import { useParams } from "react-router-dom";
import DateField from "../../helpers/DateField";
import "./AddTicket.scss";


const AddTicket = () => {
    const navigate = useNavigate();
    const toastOptions = useToast();
    const { eventId } = useParams();
    const token = localStorage.getItem("token");

    const initialValues = {
        event_id: eventId,
        weekend: '',
        categories: [],
        dates: [''],
        prices: {
            ga: '',
            vip: ''
        },
    };

    return (
        <>
            <Header />
            <Formik
                initialValues={initialValues}
                validationSchema={addTicketSchema}
                onSubmit={async (values, { resetForm }) => {
                    console.log(values, "Form Values");
                    const { categories, prices, ...rest } = values;
                    try {
                        for (let category of categories) {
                            const response = await axios.post(`${process.env.REACT_APP_API_URL}/v1/admin/ticket/save`, {
                                ...rest,
                                category,
                                price: prices[category]
                            }, {
                                headers: {
                                    'Authorization': `Bearer ${token}`,
                                    'Content-Type': 'application/json',
                                },
                            });
                            toast.success(`Ticket for ${category} added successfully`, toastOptions);
                        }
                        resetForm();
                    } catch (error) {
                        console.error("Error during form submission:", error);
                        const errorMessage = error.response?.data?.error || `Error adding ticket`;
                        toast.error(errorMessage, toastOptions);
                    }
                }}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ errors, touched, values, setFieldValue, submitCount, resetForm }) => (
                    <Form className="form">
                        <div className="row">
                            <div className="col-md-12 pb-4">
                                <Field
                                    name="weekend"
                                    as={TextField}
                                    label="Weekend"
                                    variant="outlined"
                                    fullWidth
                                    error={submitCount > 0 && touched.weekend && Boolean(errors.weekend)}
                                    helperText={submitCount > 0 && touched.weekend && errors.weekend}
                                />
                            </div>
                            <div className="col-md-12 pb-4">
                                <FieldArray name="dates">
                                    {({ remove, push }) => (
                                        <div>
                                            {values.dates.map((date, index) => (
                                                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                                                    <DateField
                                                      name={`dates[${index}]`}
                                                      label={`Date ${index + 1}`}
                                                      touched={touched.dates?.[index] ? { [`dates[${index}]`]: touched.dates[index] } : {}}
                                                      errors={errors.dates?.[index] ? { [`dates[${index}]`]: errors.dates[index] } : {}}
                                                    />
                                                    {index > 0 && (
                                                        <button
                                                            type="button"
                                                            onClick={() => remove(index)}
                                                            className="btn remove-date"
                                                        >
                                                            Remove
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                onClick={() => push('')}
                                                className="btn add-date"
                                            >
                                                Add Date
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>
                                {submitCount > 0 && errors.dates && typeof errors.dates === 'string' ? (
                                    <div className="error">{errors.dates}</div>
                                ) : null}
                            </div>
                            <div className="col-md-12 pb-4">
                                <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="categories"
                                                value="ga"
                                                checked={values.categories.includes('ga')}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setFieldValue('categories', [...values.categories, e.target.value]);
                                                    } else {
                                                        setFieldValue('categories', values.categories.filter(category => category !== e.target.value));
                                                    }
                                                }}
                                            />
                                        }
                                        label="General Admission"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="categories"
                                                value="vip"
                                                checked={values.categories.includes('vip')}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setFieldValue('categories', [...values.categories, e.target.value]);
                                                    } else {
                                                        setFieldValue('categories', values.categories.filter(category => category !== e.target.value));
                                                    }
                                                }}
                                            />
                                        }
                                        label="VIP"
                                    />
                                </div>
                                {values.categories.includes('ga') && (
                                    <div className="col-md-6 pb-4">
                                        <Field
                                            name="prices.ga"
                                            as={TextField}
                                            label="General Admission Price"
                                            variant="outlined"
                                            fullWidth
                                            type="number"
                                            error={submitCount > 0 && touched.prices?.ga && Boolean(errors.prices?.ga)}
                                            helperText={submitCount > 0 && touched.prices?.ga && errors.prices?.ga}
                                        />
                                    </div>
                                )}
                                {values.categories.includes('vip') && (
                                    <div className="col-md-6 pb-4">
                                        <Field
                                            name="prices.vip"
                                            as={TextField}
                                            label="VIP Price"
                                            variant="outlined"
                                            fullWidth
                                            type="number"
                                            error={submitCount > 0 && touched.prices?.vip && Boolean(errors.prices?.vip)}
                                            helperText={submitCount > 0 && touched.prices?.vip && errors.prices?.vip}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="submit-div mt-5 d-flex">
                                <button type="submit" className="btn submit">Submit</button>
                                <button type="button" className="btn cancel"
                                    onClick={() => {
                                        resetForm();
                                        navigate('/dashboard');
                                    }}>Close</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default AddTicket;

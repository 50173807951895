import { yupToFormErrors } from "formik";
import * as yup from "yup";

export const loginSchema = yup.object({
  email: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  password: yup.string().required("Password is required"),
});

export const eventSchema = yup.object().shape({
  event_name: yup.string().required("Event name is required"),
  event_type: yup.string().required("Event type is required"),
  event_website_url: yup
    .string()
    .url("Invalid URL")
    .required("Event website URL is required"),
  event_start_date: yup.date().required("Event start date is required"),
  event_end_date: yup.date().required("Event end date is required"),
  event_venue_name: yup.string(),
  event_venue_city: yup.string(),
  event_venue_state: yup.string(),
  event_venue_country: yup.string(),
  event_venue_zip_code: yup.string(),
  latitude: yup.string(),
  longitude: yup.string(),
  event_genre_type: yup
    .array()
    .min(1, "Genre type is required")
    .required("Genre type is required"),
  non_traditional_event_genre_type: yup.array(),

  event_spotify_playlist: yup.string().url("Invalid URL"),
  event_apple_playlist: yup.string().url("Invalid URL"),
  event_facebook_link: yup.string().url("Invalid URL"),
  event_instagram_link: yup.string().url("Invalid URL"),
  event_twitter_link: yup.string().url("Invalid URL"),
  event_linkedin_link: yup.string().url("Invalid URL"),
  about: yup.string(),
  eventTimes: yup
    .array()
    .of(
      yup.object().shape({
        day: yup.string().required("Day is required"),
        timeFrom: yup.string().required("From time is required"),
        timeTo: yup.string().required("To time is required"),
      })
    )
    .min(1, "At least one event time slot is required"),
});

export const addPerformerSchema = yup.object().shape({
  name: yup.string().required("Performer Name is required"),
  url: yup.string().url("Invalid URL").required("URL is required"),
  performanceDate: yup.date().required("Performance Date is required"),
  performanceTimeFrom: yup
    .string()
    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
    .required("Performance Time From is required"),
  performanceTimeTo: yup
    .string()
    .matches(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/, "Invalid time format")
    .required("Performance Time To is required"),
});

export const addTicketSchema = yup.object().shape({
  weekend: yup.string().required("Weekend is required"),
  categories: yup
    .array()
    .of(yup.string())
    .min(1, "At least one category must be selected")
    .required("Categories are required"),
  dates: yup
    .array()
    .of(yup.date().required("Date is required"))
    .required("Dates are required"),
  prices: yup
    .object()
    .shape({
      ga: yup.number().when("categories", {
        is: (categories) => categories && categories.includes("ga"),
        then: yup.number().required("Price for General Admission is required"),
      }),
      vip: yup.number().when("categories", {
        is: (categories) => categories && categories.includes("vip"),
        then: yup.number().required("Price for VIP is required"),
      }),
    })
    .required("Prices are required"),
});

export const publicTribeSchema = yup.object({
  name: yup.string().required("Name is required"),
});

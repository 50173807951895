import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../header/Header";
import { toast } from "react-toastify";
import useToast from "../../hooks/useToast";
import axios from "axios";
import "./AddPerformer.scss";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

const AddPerformer = () => {
  const { eventId } = useParams(); // Extract eventId from URL parameters
  const navigate = useNavigate();
  const toastOptions = useToast();

  const token = localStorage.getItem("token");

  const initialValues = {
    event_id: eventId, // Set initial values including eventId
    file: null,
  };

  const addPerformerSchema = Yup.object().shape({
    file: Yup.mixed().required("A CSV file is required"),
  });

  const handleCSVChange = (event, setFieldValue) => {
    setFieldValue("file", event.currentTarget.files[0]);
  };

  return (
    <>
      <Header />
      <div className="container">
        <h2 className="mt-4">Add Performer</h2>
        <Formik
          initialValues={initialValues}
          validationSchema={addPerformerSchema}
          onSubmit={async (values, { resetForm }) => {
            const formData = new FormData();
            formData.append("event_id", values.event_id); // Append eventId
            formData.append("file", values.file);

            try {
              const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/v1/admin/add-csv/performer/save`,
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                  },
                }
              );
              resetForm();
              toast.success(response?.data?.message, toastOptions);
              setTimeout(() => {
                navigate(`/add-ticket/${eventId}`);
              }, 1000)
            } catch (error) {
              const errorMessage =
                error.response?.data?.error || "Error uploading CSV file";
              toast.error(errorMessage, toastOptions);
            }
          }}
        >
          {({ errors, touched, setFieldValue, resetForm }) => (
            <Form className="form mt-4">
              <div className="row align-items-center">
                <div className="col-md-6 pb-4">
                  <TextField
                    id="file"
                    name="file"
                    type="file"
                    accept=".csv"
                    onChange={(event) => handleCSVChange(event, setFieldValue)}
                    fullWidth
                  />
                  {touched.file && errors.file && (
                    <div className="error">{errors.file}</div>
                  )}
                </div>
                <div className="col-md-6 pb-4">
                  <Button type="submit" variant="contained" color="primary">
                    Upload
                  </Button>
                </div>
                <div className="col-md-12 d-flex justify-content-end mt-4">
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="button"
                    className="btn cancel"
                    onClick={() => {
                      resetForm();
                      navigate("/dashboard");
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    className="ml-2 btn submit"
                    onClick={() => {
                      resetForm();
                      navigate(`/add-ticket/${eventId}`);
                    }}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddPerformer;


{
  /*
import React from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import Header from "../header/Header";
import { formatPerfomanceData } from "../../utils/formatUtils";
import { useParams } from "react-router-dom";
import { addPerformerSchema } from "../../helpers/validator";
import { toast } from "react-toastify";
import useToast from "../../hooks/useToast";
import axios from "axios";
import "./AddPerformer.scss";
// import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import format from 'date-fns/format';
// import parseISO from 'date-fns/parseISO';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

import DateField from "../../helpers/DateField";

const AddPerformer = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const toastOptions = useToast();

  const token = localStorage.getItem("token");

  const initialValues = {
    eventId: eventId,
    name: "",
    url: "",
    performanceDate: "",
    performanceTimeFrom: "",
    performanceTimeTo: "",
  };

  return (
    <>
      <Header />
      <Formik
        initialValues={initialValues}
        validationSchema={addPerformerSchema}
        onSubmit={async (values, { resetForm }) => {
          const formattedData = formatPerfomanceData(values);
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/v1/admin/performer/save`,
              formattedData,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
            toast.success("Performer added successfully", toastOptions);
            resetForm();
          } catch (error) {
            const errorMessage =
              error.response?.data?.error || "Error adding performer";
            toast.error(errorMessage, toastOptions);
          }
        }}
      >
        {({ errors, touched, resetForm }) => (
          <Form className="form">
            <div className="row">
              <div className="col-md-6 pb-4">
                <Field
                  name="name"
                  as={TextField}
                  label="Performer Name"
                  variant="outlined"
                  fullWidth
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </div>
              <div className="col-md-6 pb-4">
                <Field
                  name="url"
                  as={TextField}
                  label="URL"
                  variant="outlined"
                  fullWidth
                  error={touched.url && Boolean(errors.url)}
                  helperText={touched.url && errors.url}
                />
              </div>
              <div className="col-md-6 pb-4">
                <DateField
                  name="performanceDate"
                  label="Performance Date"
                  touched={touched}
                  errors={errors}
                />
              </div>
              <div className="col-md-6 pb-4">
                <Field
                  name="performanceTimeFrom"
                  as={TextField}
                  label="Performance Time From"
                  type="time"
                  variant="outlined"
                  fullWidth
                  error={
                    touched.performanceTimeFrom &&
                    Boolean(errors.performanceTimeFrom)
                  }
                  helperText={
                    touched.performanceTimeFrom && errors.performanceTimeFrom
                  }
                />
              </div>
              <div className="col-md-6 pb-4">
                <Field
                  name="performanceTimeTo"
                  as={TextField}
                  label="Performance Time To"
                  type="time"
                  variant="outlined"
                  fullWidth
                  error={
                    touched.performanceTimeTo &&
                    Boolean(errors.performanceTimeTo)
                  }
                  helperText={
                    touched.performanceTimeTo && errors.performanceTimeTo
                  }
                />
              </div>
              <div className="submit-div mt-5 d-flex">
                <button type="submit" className="btn submit">
                  Submit
                </button>
                <button
                  type="button"
                  className="btn cancel"
                  onClick={() => {
                    resetForm();
                    navigate("/dashboard");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn submit"
                  onClick={() => {
                    resetForm();
                    navigate(`/add-ticket/${eventId}`);
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddPerformer;

  */
}

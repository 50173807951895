import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import useToast from "../../hooks/useToast";
import { useNavigate } from "react-router-dom";
import { DEBOUNCE_TIME } from "../../helpers/eventHelper";

const headCells = [
  { id: "serial", numeric: false, disablePadding: false, label: "S.No" },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  { id: "email", numeric: false, disablePadding: false, label: "Email" },
  { id: "feedback", numeric: false, disablePadding: false, label: "Feedback" },
  { id: "rating", numeric: false, disablePadding: false, label: "Rating" },
];

function SuggestionTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

SuggestionTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function SuggestionTableToolbar() {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <h1>Users Feedback</h1>
      </Typography>
      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

export default function SuggestionTable() {
  const navigate = useNavigate();
  const toastOptions = useToast();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("email");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [totalDocs, setTotalDocs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const token = localStorage.getItem("token");

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/admin/user/feedbacks?page=${
          page + 1
        }&limit=${rowsPerPage}&sort_by=${orderBy}&search_query=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = result.data.data.docs.map((feedback) => ({
        id: feedback._id,
        first_name: feedback.user?.first_name || "N/A",
        last_name: feedback.user?.last_name || "N/A",
        email: feedback.user?.email || "N/A",
        feedback: feedback.feedback,
        rating: feedback.star,
      }));
      setRows(data);
      setTotalDocs(result.data.data.totalDocs);
    } catch (error) {
      toast.error(error?.response?.data?.error, toastOptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [order, page, rowsPerPage, searchQuery]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //  useEffect(() => {
  //    let timer;
  //    setPage(1);
  //    timer = setTimeout(() => {
  //      fetchData();
  //    }, DEBOUNCE_TIME);

  //    return () => {
  //      clearTimeout(timer);
  //    };
  //  }, [searchQuery]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <SuggestionTableToolbar />
        <TableContainer>
          
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <SuggestionTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    style={{ textAlign: "center", fontSize: "1rem" }}
                  >
                    Loading...
                  </TableCell>
                </TableRow>
              ) : rows.length ? (
                rows.map((row, index) => (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                    <TableCell>
                      {row.first_name + " " + row.last_name}
                    </TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.feedback}</TableCell>
                    <TableCell>{row.rating}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={6}
                    style={{ textAlign: "center", fontSize: "1rem" }}
                  >
                    No record found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </Box>
  );
}

{
  /*
  <div className="d-flex justify-content-center align-items-center my-3">
            <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              className="w-50"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
          </div>
           */
}
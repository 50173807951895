import { FaUserAlt } from "react-icons/fa";
import { CiMenuKebab } from "react-icons/ci";
import "./Reports.scss";
import Header from "../header/Header";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import cardHire from "../../assets/images/hiring-card-img.jpg";
import cardHireimg from "../../assets/images/chris-hire.jpg";
import cardHireimg2 from "../../assets/images/scott-hire.jpg";
import cardHireimg3 from "../../assets/images/clem-hiring.jpg";
import profileadd from "../../assets/images/../../assets/images/user-add-removebg-preview.png";
import invoice from "../../assets/images/../../assets/images/invoice-img-removebg-preview.png";
import messeges from "../../assets/images/../../assets/images/chat-bot-removebg-preview.png";
import hand from "../../assets/images/../../assets/images/laptop.png";
import EnhancedTable from "../transactions/EnhancedTable";
import { useSelector } from "react-redux";

export const Report = () => {
  const token = useSelector((state) => state.auth.token);

  return (
    <div className="reports">
      <Header />

      <div className="row">
        <div className="col-12 main-table ms-2 mt-5">
          <EnhancedTable />
        </div>
      </div>
    </div>
  );
};

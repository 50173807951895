import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "../header/Header";
import "./UserCount.scss";
import useToast from "../../hooks/useToast";
import { toast } from "react-toastify";

const UserCount = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [userCount, setUserCount] = useState(null);
  const [loginUserCount, setLoginUserCount] = useState(null);
  const [error, setError] = useState("");
  const token = localStorage.getItem("token");
  const toastOptions = useToast();

  useEffect(() => {
    // Calculate default date range from today to last 7 days
    const today = new Date();
    const last7Days = new Date(today);
    last7Days.setDate(today.getDate() - 7);

    // Format dates as YYYY-MM-DD
    const formatDate = (date) => date.toISOString().split("T")[0];

    setFromDate(formatDate(last7Days));
    setToDate(formatDate(today));
  }, []);

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        let params = {};

        if (fromDate && toDate) {
          params = { from: fromDate, to: toDate };
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/admin/count/signup/users`,
          {
            params: params,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Show success toast only if both dates are set
        if (fromDate && toDate) {
          // toast.success(response?.data?.message, toastOptions);
        }
        setUserCount(response.data.data.userCount);
        setLoginUserCount(response.data.data.loginUserCount);

        setError("");
      } catch (error) {
        toast.error(
          "Failed to fetch user count. Please try again.",
          toastOptions
        );
        console.error(error);
      }
    };

    fetchUserCount();
  }, [fromDate, toDate, token, toastOptions]);

  return (
    <>
      <Header />
      <div className="container">
        <h2 className="mt-4">Users Count</h2>
        <div className="row mt-4">
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="fromDate">From Date:</label>
              <input
                type="date"
                id="fromDate"
                className="form-control"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="toDate">To Date:</label>
              <input
                type="date"
                id="toDate"
                className="form-control"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="count-container">
          <div className="count-box">
            <h4>
              {userCount !== null
                ? `Total New Users: ${userCount}`
                : "Loading..."}
            </h4>
          </div>
          <div className="plain-box">
            <h4>
              {loginUserCount !== null
                ? `Logged In User : ${loginUserCount}`
                : "Loading..."}
            </h4>
          </div>
        </div>

        {error && <p className="text-danger mt-3">{error}</p>}
      </div>
    </>
  );
};

export default UserCount;

import React from "react";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const DateFieldFormik = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  error,
  helperText,
}) => {
  return (
    <DatePicker
      label={label}
      value={value ? moment(value) : null}
      onChange={(date) => {
        onChange(name, date ? date.toISOString() : "");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          error={error}
          helperText={helperText}
          onBlur={onBlur}
        />
      )}
      inputFormat="MM/DD/YYYY"
    />
  );
};

export default DateFieldFormik;

// Function to convert 24-hour time format to 12-hour format
export const convertTimeFormat = (timeStr) => {
    let [hours, minutes] = timeStr.split(':').map(Number);
    const type = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 || 12;
  
    return {
      time: `${hours}:${minutes.toString().padStart(2, '0')}`,
      type: type,
    };
  };

  //Converts a 12-hour time format to 24-hour format.
  export const convertTo24HourFormat = ({ time, type }) => {
    if (!time || typeof time !== 'string') {
      throw new Error('Invalid time format');
    }
  
    let [hours, minutes] = time.split(':').map(Number);
  
    if (type !== 'am' && type !== 'pm') {
      throw new Error('Invalid type. Must be "am" or "pm".');
    }
  
    if (type === 'pm' && hours !== 12) {
      hours += 12;
    } else if (type === 'am' && hours === 12) {
      hours = 0;
    }
  
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };
  
  // Function to reformat response data
  export const formatEventData = (data) => {
    const formattedEventTimes = Array.isArray(data.event_times)
      ? data.event_times.map((event_times) => ({
          day: event_times.day,
          timeFrom: event_times.timeFrom,
          timeTo: event_times.timeTo,
        }))
      : [];
    
    return {
      event_name: data.event_name,
      event_type: data.event_type,
      event_website_url: data.event_website_url,
      event_start_date: data.event_start_date,
      event_end_date: data.event_end_date,
      event_venue: {
        name: data.event_venue_name,
        city: data.event_venue_city,
        state: data.event_venue_state,
        country: data.event_venue_country,
        zip_code: data.event_venue_zip_code,
        geoLocation: {
          type: "Point",
          coordinates: [data.longitude, data.latitude],
        },
      },
      event_genre_type: data.event_genre_type,
      non_traditional_event_genre_type: data.non_traditional_event_genre_type,
      about: data.about,
      event_spotify_playlist: data.event_spotify_playlist,
      event_apple_playlist: data.event_apple_playlist,
      event_social_media: {
        facebook: data.event_facebook_link,
        instagram: data.event_instagram_link,
        twitter: data.event_twitter_link,
        linkedin: data.event_linkedin_link,
      },
      event_times: formattedEventTimes,
    };
  };
  



  // Function to reformat response data
  export const formatPerfomanceData = (data) => {
    const performanceTimeFrom = convertTimeFormat(data.performanceTimeFrom);
    const performanceTimeTo = convertTimeFormat(data.performanceTimeTo);
  
    return {
        event_id: data.eventId,
      name: data.name,
      url: data.url,
      performance_date: data.performanceDate,
      performance_time: {
        from: {
          time: performanceTimeFrom.time,
          type: performanceTimeFrom.type,
        },
        to: {
          time: performanceTimeTo.time,
          type: performanceTimeTo.type,
        },
      },
    };
  };
  
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getTicketsOfAnEvent } from '../../api/api';
import { TICKET_TYPES } from '../../helpers/eventHelper';

function EventTickets() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [ticketInfo, setTicketInfo] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getTicketOfEvent = async () => {
      setLoading(true);
      const res = await getTicketsOfAnEvent(eventId);

      if (res.statusCode === 200) {
        setTicketInfo(res.data);
      }
      setLoading(false);
    };

    getTicketOfEvent();
  }, [eventId]);

  const handleAddTicket = () => {
    navigate(`/add-ticket/${eventId}`)
  }


  return (
    <div>
      {
        loading ?
          <p>Loading....</p>
          :
          ticketInfo.length ?
            <div className='row'>
              {
                ticketInfo.map((ticket) => (
                  <div className='col-md-6 gx-5'>
                    <h3>{TICKET_TYPES[ticket.category]}</h3>
                    <p>{ticket.weekend}</p>
                    <p>${ticket.price} + FEES</p>
                  </div>
                ))
              }
            </div>
            :
            <div>
              <h3>No tickets found for this event. Please enter the ticket details.</h3>
              <button onClick={handleAddTicket}>Add Ticket</button>
            </div>
      }
    </div>
  )
}

export default EventTickets
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import TribeModal from '../tribeModal/TribeModal';
import {Button, Grid, Select, TextField} from '@mui/material';
import { DEBOUNCE_TIME } from '../../helpers/eventHelper';
import { toast } from 'react-toastify';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import {  Switch, FormControlLabel } from "@mui/material";
import useToast from '../../hooks/useToast';
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const headCells = [
  { id: 'event_name', numeric: false, disablePadding: false, label: 'Event Name' },
  { id: 'event_type', numeric: false, disablePadding: false, label: 'Event Type' },
  { id: 'about', numeric: false, disablePadding: false, label: 'About' },
  { id: 'event_start_date', numeric: false, disablePadding: false, label: 'Start Date' },
  { id: 'event_end_date', numeric: false, disablePadding: false, label: 'End Date' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'action', numeric: false, disablePadding: false, label: 'Action' }
];


function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EnhancedTableToolbar(props) {
  return (
    <Toolbar
      sx={{
        // pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography variant="h6" id="tableTitle" component="div">
        Event Table
      </Typography>
      {/* <Tooltip title="Filter list"> */}
        <IconButton>
          <FilterListIcon />
        </IconButton>
      {/* </Tooltip> */}
    </Toolbar>
  );
}

export default function EnhancedTable() {
  const navigate = useNavigate();
  const toastOptions = useToast();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('event_name');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [totalDocs, setTotalDocs] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [toggleStates, setToggleStates] = useState({});
  const [eventType, setEventType] = useState("all");
  const [eventSource, setEventSource] = useState("All");

  const token = localStorage.getItem("token")

  const fetchData = async () => {
    setLoading(true);
    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/admin/event`,
        {
          params: {
            order: -1,
            page,
            limit: rowsPerPage,
            sort_by: orderBy,
            search_query: searchQuery,
            ...(eventType === "all" ? {} : {event_type: eventType}),
            ...(eventSource === "All" ? {} : {event_source: eventSource}),
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = result.data.data.docs.map((event) => ({
        id: event._id,
        event_name: event.event_name,
        event_type: event.event_type,
        about: event.about,
        event_start_date: event.event_start_date,
        event_end_date: event.event_end_date,
      }));
      setRows(data);
      setTotalDocs(result.data.data.totalDocs);

      // Initialize toggle states with default value true
      const initialToggleStates = {};
      data.forEach((row) => {
        initialToggleStates[row.id] = true; // Default value set to true
      });
      setToggleStates(initialToggleStates);
    } catch (error) {
      toast.error(error?.response?.data?.error)
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [page, orderBy, rowsPerPage]);

  // useEffect(() => {
  //   let timer;
  //   setPage(1);
  //   timer = setTimeout(() => {
  //     fetchData();
  //   }, DEBOUNCE_TIME);
  //
  //   return () => {
  //     clearTimeout(timer);
  //   }
  // }, [searchQuery])


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleAddClick = (id) => {
    setSelectedEventId(id);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedEventId(null);
  };

  const handleEditClick = (eventId) => {
    navigate("/edit-event/"+eventId);
  }

  const handleEditTicketClick = (eventId) => {
    navigate(`/event-tickets/${eventId}`)
  }


  const handleToggleChange = async (eventId) => {
    // Capture the current state before making any changes
    setToggleStates((prev) => {
      const newState = !prev[eventId];

      // Return the new state immediately to update the UI
      const updatedState = {
        ...prev,
        [eventId]: newState,
      };

      // Make the API call to update the event status
      (async () => {
        const token = localStorage.getItem("token");
        try {
          const response = await axios.patch(
            `${process.env.REACT_APP_API_URL}/v1/admin/event/status/update`,
            {
              _id: eventId,
              status: newState,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          toast.success(response?.data?.message, toastOptions);
        } catch (error) {
          toast.error("Error updating event status", toastOptions);

          // Revert the state change if the API call fails
          setToggleStates((prev) => ({
            ...prev,
            [eventId]: !newState, // Revert to previous state
          }));
        }
      })();

      return updatedState;
    });
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', p: 4, mb: 2 }}>
        {/*<EnhancedTableToolbar />*/}
        <h5>Events</h5>
        <TableContainer>
          <div className='d-flex my-3'>
            <TextField fullWidth size="small" id="outlined-basic" label="Search" variant="outlined" onChange={(e) => setSearchQuery(e.target.value)} value={searchQuery} />
            <Button className="ml-2" variant="contained" color="primary" onClick={fetchData}>Search</Button>
          </div>
          <Grid container spacing={2} sx={{alignItems: "center"}}>
            <Grid item xs={3}>
              <FormControl fullWidth size={"small"}>
                <InputLabel id="event-type-select-label">Event Type</InputLabel>
                <Select
                  labelId="event-type-select-label"
                  value={eventType}
                  label={"Event Type"}
                  onChange={(e) => {setEventType(e.target.value)}}
                >
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"festival"}>Festival</MenuItem>
                  <MenuItem value={"concert"}>Concert</MenuItem>
                  <MenuItem value={"sports"}>Sports</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth size={"small"}>
                <InputLabel id="event-source-label">Event Source</InputLabel>
                <Select
                  labelId="event-source-label"
                  value={eventSource}
                  label={"Event Source"}
                  onChange={(e) => {setEventSource(e.target.value)}}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"JamBase"}>JamBase</MenuItem>
                  <MenuItem value={"TicketMaster"}>TicketMaster</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {
                loading ?
                  <TableRow>
                    <TableCell colSpan={6} style={{ textAlign: "center", fontSize: "1rem" }}>Loading...</TableCell>
                  </TableRow>
                  :
                  rows.length ?
                    rows.map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.id}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell component="th" id={labelId} scope="row">
                            {row.event_name}
                          </TableCell>
                          <TableCell>{row.event_type}</TableCell>
                          <TableCell>{row.about}</TableCell>
                          <TableCell>
                            {new Date(
                              row.event_start_date
                            ).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {row.event_end_date && new Date(row.event_end_date).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={toggleStates[row.id] || false}
                                  onChange={() => handleToggleChange(row.id)}
                                />
                              }
                              label={
                                toggleStates[row.id]
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <div className="d-flex">
                              <Tooltip title="Add Public Tribe">
                                <IconButton
                                  onClick={() => handleAddClick(row.id)}
                                >
                                  <AddIcon />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Edit Event">
                                <IconButton
                                  onClick={() => handleEditClick(row.id)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>

                              {/* <IconButton >
                                <DeleteIcon onClick={() => handleDeleteEventClick(row.id)}/>
                              </IconButton> */}
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })
                    :
                    <TableRow>
                      <TableCell colSpan={6} style={{ textAlign: "center", fontSize: "1rem" }}>No record found.</TableCell>
                    </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={totalDocs}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <TribeModal
        open={modalOpen}
        handleClose={handleModalClose}
        eventId={selectedEventId}
      />
    </Box>
  );
}

{
  /* 
  <Tooltip title="View Ticket">
                                <IconButton
                                  onClick={() => handleEditTicketClick(row.id)}
                                >
                                  <ConfirmationNumberIcon />
                                </IconButton>
                              </Tooltip>
                                */
}
import React, { useState } from "react";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Routes, Route } from "react-router-dom";
import DashboardPanel from "./components/dashboard/common/DashboardPanel";
import Login from "./components/auth/login/Login";
import { Homepage } from "./components/homepage/Homepage";
import { Notfound } from "./components/notfound/Notfound";
import Form from "./components/forms/Form";
import DescriptionCard from "./components/cards/DescriptionCards";
import { Report } from "./components/reports/Reports";
import { Outlet } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import Header from "./components/header/Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddPerformer from "./components/addPerformer/AddPerformer";
import AddEvent from "./components/addEvent/AddEvent";
import AddTicket from "./components/addTicket/AddTicket";
import { useLocation } from "react-router-dom";
import EditEvent from "./components/editEvent/EditEvent";
import EventTickets from "./components/eventTickets/EventTickets";
import LineupInformations from "./components/LinupInformation/LineupInformations";
import MarketingInformation from "./components/marketingInformation/MarketingInformation";
import EventInformation from "./components/eventInformation/EventInformation";
import AdditionalInformation from "./components/additionalInformation/AdditionalInformation";
import Suggesion from "./components/suggestions/Suggesion";
import UserCount from "./components/UsersCount/UserCount";
import EditLineupInformation from "./components/EditLineupInformation/EditLineupInformation";
import EditMarketingInformation from "./components/EditMarketingInformation/EditMarketingInformation";
import EditTicketInformation from "./components/EditEventTicketInformation/EditTicketInformation";
import EditAdditionalInformation from "./components/EditAdditionalInformation/EditAdditionalInformation";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const sidebarRoutes = [
    /^\/dashboard$/,
    /^\/events$/,
    /^\/profile$/,
    /^\/add-performer\/.+$/,
    /^\/add-ticket\/.+$/,
    /^\/event-tickets\/.+$/,
    /^\/edit-event\/.+$/,
    /^\/add-event$/,
    /^\/suggestion$/,
    /^\/lineup-information\/.+$/,
    /^\/marketing-information\/.+$/,
    /^\/event-information\/.+$/,
    /^\/additional-information\/.+$/,
    /^\/form$/,
    /^\/edit-lineup\/.+$/,
    /^\/edit-marketing\/.+$/,
    /^\/edit-ticket-information\/.+$/,
    /^\/edit-additional-information\/.+$/,
  ];

  const shouldShowSidebar = sidebarRoutes.some((route) =>
    route.test(location.pathname)
  );

  return (
    <div>
      <div className="main">
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>

        {shouldShowSidebar && <Sidebar isSidebarOpen={isSidebarOpen} />}

        <div className="dashboardContent p-2">
          <Routes>
            <Route path="dashboard" element={<UserCount />} />
            <Route path="events" element={<Report />} />
            <Route path="add-event" element={<AddEvent />} />
            <Route path="suggestion" element={<Suggesion />} />
            <Route
              path="lineup-information/:eventId"
              element={<LineupInformations />}
            />
            <Route
              path="marketing-information/:eventId"
              element={<MarketingInformation />}
            />
            <Route
              path="event-information/:eventId"
              element={<EventInformation />}
            />
            <Route
              path="additional-information/:eventId"
              element={<AdditionalInformation />}
            />
            <Route path="edit-event/:eventId" element={<EditEvent />} />
            <Route path="event-tickets/:eventId" element={<EventTickets />} />
            <Route path="add-performer/:eventId" element={<AddPerformer />} />
            <Route path="add-ticket/:eventId" element={<AddTicket />} />
            <Route path="form" element={<Form />} />
            <Route path="cards" element={<DescriptionCard />} />
            <Route
              path="edit-lineup/:eventId"
              element={<EditLineupInformation />}
            />
            <Route
              path="edit-marketing/:eventId"
              element={<EditMarketingInformation />}
            />
            <Route
              path="edit-ticket-information/:eventId"
              element={<EditTicketInformation />}
            />
            <Route
              path="edit-additional-information/:eventId"
              element={<EditAdditionalInformation />}
            />
          </Routes>

          <Outlet />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TextField, Button } from "@mui/material";
import Header from "../header/Header";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import useToast from "../../hooks/useToast";
import { toast } from "react-toastify";
import "./EditLineupInformation.scss";

const EditLineupInformation = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const toastOptions = useToast();
  const token = localStorage.getItem("token");
  const [lineupDetails, setLineupDetails] = useState(null);
  const lineupId = localStorage.getItem("lineupId");
  const [isUpdated, setIsUpdated] = useState(true);

  const formatDate = (date) => {
    return date?.split("T")[0];
  };

  const initialValues = lineupDetails
    ? {
        event_id: eventId,
        headliner_name: lineupDetails.headliner_name || "",
        performance_date: formatDate(lineupDetails?.performance_date) || "",
        time_from: lineupDetails.time_from || "",
        time_to: lineupDetails.time_to || "",
        artist_website: lineupDetails.artist_website || "",
        spotify_playlist: lineupDetails.spotify_playlist || "",
        apple_music_playlist: lineupDetails.apple_music_playlist || "",
        lineup_artist_name: lineupDetails.lineup_artist_name || "",
        lineup_artist_performance_date:
          formatDate(lineupDetails?.lineup_artist_performance_date) || "",
        lineup_artist_time_from: lineupDetails.lineup_artist_time_from || "",
        lineup_artist_time_to: lineupDetails.lineup_artist_time_to || "",
        lineup_artist_website: lineupDetails.lineup_artist_website || "",
        lineup_artist_spotify_playlist:
          lineupDetails.lineup_artist_spotify_playlist || "",
        lineup_artist_apple_music_playlist:
          lineupDetails.lineup_artist_apple_music_playlist || "",
      }
    : {
        event_id: eventId,
        headliner_name: "",
        performance_date: "",
        time_from: "",
        time_to: "",
        artist_website: "",
        spotify_playlist: "",
        apple_music_playlist: "",
        lineup_artist_name: "",
        lineup_artist_performance_date: "",
        lineup_artist_time_from: "",
        lineup_artist_time_to: "",
        lineup_artist_website: "",
        lineup_artist_spotify_playlist: "",
        lineup_artist_apple_music_playlist: "",
      };

  const validationSchema = Yup.object({
    headliner_name: Yup.string(),
    performance_date: Yup.date().nullable(),
    time_from: Yup.string(),
    time_to: Yup.string(),
    artist_website: Yup.string().url("Enter a valid URL"),
    spotify_playlist: Yup.string().url("Enter a valid URL"),
    apple_music_playlist: Yup.string().url("Enter a valid URL"),
    lineup_artist_name: Yup.string(),
    lineup_artist_performance_date: Yup.date()
      .nullable() // Allows the field to be null
      .notRequired(),
    lineup_artist_time_from: Yup.string(),
    lineup_artist_time_to: Yup.string(),
    lineup_artist_website: Yup.string().url("Enter a valid URL"),
    lineup_artist_spotify_playlist: Yup.string().url("Enter a valid URL"),
    lineup_artist_apple_music_playlist: Yup.string().url("Enter a valid URL"),
  });

  const handleSubmit = async (values) => {
    try {
      const url = lineupDetails
        ? `${process.env.REACT_APP_API_URL}/v1/admin/lineup/update`
        : `${process.env.REACT_APP_API_URL}/v1/admin/lineup/save`;

      const method = lineupDetails ? "patch" : "post";

      const response = await axios({
        method: method,
        url: url,
        data: values,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      toast.success(response?.data?.message, toastOptions);
      setIsUpdated(!isUpdated);
    } catch (error) {
      toast.error(
        `Error ${lineupDetails ? "updating" : "creating"} Lineup`,
        toastOptions
      );
    }
  };

  useEffect(() => {
    const fetchLineupDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/v1/admin/lineup/${eventId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLineupDetails(response.data?.data?.lineup || null);
      } catch (error) {
        console.error("Error fetching lineup details:", error);
      }
    };

    fetchLineupDetails();
  }, [token, lineupId, isUpdated]);

  return (
    <>
      <Header />
      <div className="container">
        <h2 className="mt-4">Lineup Information</h2>
        <Formik
          initialValues={lineupDetails || initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <Form onSubmit={handleSubmit} className="form">
              <div className="row">
                <div className="col-md-6 pb-4">
                  <TextField
                    name="headliner_name"
                    label="Headliner Na"
                    variant="outlined"
                    fullWidth
                    value={values.headliner_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.headliner_name && !!errors.headliner_name}
                    helperText={touched.headliner_name && errors.headliner_name}
                  />
                </div>
                <div className="col-md-6 pb-4">
                  <TextField
                    name="lineup_artist_name"
                    label="Other Lineup Artists"
                    variant="outlined"
                    fullWidth
                    value={values.lineup_artist_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.lineup_artist_name && !!errors.lineup_artist_name
                    }
                    helperText={
                      touched.lineup_artist_name && errors.lineup_artist_name
                    }
                  />
                </div>
                <div className="col-md-6 pb-4">
                  <TextField
                    name="performance_date"
                    label="Date of Performance"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={formatDate(values.performance_date)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.performance_date && !!errors.performance_date
                    }
                    helperText={
                      touched.performance_date && errors.performance_date
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-6 pb-4">
                  <TextField
                    name="lineup_artist_performance_date"
                    label="Date of Performance"
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={formatDate(values.lineup_artist_performance_date)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.lineup_artist_performance_date &&
                      !!errors.lineup_artist_performance_date
                    }
                    helperText={
                      touched.lineup_artist_performance_date &&
                      errors.lineup_artist_performance_date
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-3 pb-4">
                  <TextField
                    name="time_from"
                    label="Time From"
                    type="time"
                    variant="outlined"
                    fullWidth
                    value={values.time_from}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.time_from && !!errors.time_from}
                    helperText={touched.time_from && errors.time_from}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-3 pb-4">
                  <TextField
                    name="time_to"
                    label="Time To"
                    type="time"
                    variant="outlined"
                    fullWidth
                    value={values.time_to}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.time_to && !!errors.time_to}
                    helperText={touched.time_to && errors.time_to}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-3 pb-4">
                  <TextField
                    name="lineup_artist_time_from"
                    label="Time From"
                    type="time"
                    variant="outlined"
                    fullWidth
                    value={values.lineup_artist_time_from}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.lineup_artist_time_from &&
                      !!errors.lineup_artist_time_from
                    }
                    helperText={
                      touched.lineup_artist_time_from &&
                      errors.lineup_artist_time_from
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-3 pb-4">
                  <TextField
                    name="lineup_artist_time_to"
                    label="Time To"
                    type="time"
                    variant="outlined"
                    fullWidth
                    value={values.lineup_artist_time_to}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.lineup_artist_time_to &&
                      !!errors.lineup_artist_time_to
                    }
                    helperText={
                      touched.lineup_artist_time_to &&
                      errors.lineup_artist_time_to
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="col-md-6 pb-4">
                  <TextField
                    name="artist_website"
                    label="Artist Website"
                    variant="outlined"
                    fullWidth
                    value={values.artist_website}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.artist_website && !!errors.artist_website}
                    helperText={touched.artist_website && errors.artist_website}
                  />
                </div>
                <div className="col-md-6 pb-4">
                  <TextField
                    name="lineup_artist_website"
                    label="Artist Website"
                    variant="outlined"
                    fullWidth
                    value={values.lineup_artist_website}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.lineup_artist_website &&
                      !!errors.lineup_artist_website
                    }
                    helperText={
                      touched.lineup_artist_website &&
                      errors.lineup_artist_website
                    }
                  />
                </div>
                <div className="col-md-6 pb-4">
                  <TextField
                    name="spotify_playlist"
                    label="Spotify Playlist"
                    variant="outlined"
                    fullWidth
                    value={values.spotify_playlist}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.spotify_playlist && !!errors.spotify_playlist
                    }
                    helperText={
                      touched.spotify_playlist && errors.spotify_playlist
                    }
                  />
                </div>
                <div className="col-md-6 pb-4">
                  <TextField
                    name="lineup_artist_spotify_playlist"
                    label="Spotify Playlist"
                    variant="outlined"
                    fullWidth
                    value={values.lineup_artist_spotify_playlist}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.lineup_artist_spotify_playlist &&
                      !!errors.lineup_artist_spotify_playlist
                    }
                    helperText={
                      touched.lineup_artist_spotify_playlist &&
                      errors.lineup_artist_spotify_playlist
                    }
                  />
                </div>
                <div className="col-md-6 pb-4">
                  <TextField
                    name="apple_music_playlist"
                    label="Apple Music Playlist"
                    variant="outlined"
                    fullWidth
                    value={values.apple_music_playlist}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.apple_music_playlist &&
                      !!errors.apple_music_playlist
                    }
                    helperText={
                      touched.apple_music_playlist &&
                      errors.apple_music_playlist
                    }
                  />
                </div>
                <div className="col-md-6 pb-4">
                  <TextField
                    name="lineup_artist_apple_music_playlist"
                    label="Apple Music Playlist"
                    variant="outlined"
                    fullWidth
                    value={values.lineup_artist_apple_music_playlist}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.lineup_artist_apple_music_playlist &&
                      !!errors.lineup_artist_apple_music_playlist
                    }
                    helperText={
                      touched.lineup_artist_apple_music_playlist &&
                      errors.lineup_artist_apple_music_playlist
                    }
                  />
                </div>
                <div className="submit-div mt-5 d-flex">
                  <Button type="submit" className="btn submit">
                    Update
                  </Button>
                  <div>
                    <Button
                      type="reset"
                      className="btn cancel"
                      style={{ marginRight: "10px" }}
                      onClick={() => navigate(`/edit-event/${eventId}`)}
                    >
                      Back
                    </Button>
                    <Button
                      type="button"
                      className="btn cancel"
                      onClick={() => navigate(`/edit-marketing/${eventId}`)}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EditLineupInformation;

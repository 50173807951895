import { createSlice } from "@reduxjs/toolkit";
// import { combineReducers } from "redux";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    isAuthenticated: false,
    role: null,
    token: null,
    _id: null,
    loading: false,
    error: null,
  },
  reducers: {
    storeInitialData: (state, action) => {
      console.log(action.payload);
      state.role = action.payload.role;
      state.isAuthenticated = true;
    },
    storeToken(state, action) {
      state.token = action.payload;
    },

    logoutUser: (state) => {
      state.isAuthenticated = false;
      state.role = null;
      state._id = null;
    },
  },
});

export const { logoutUser, storeInitialData, storeToken } = authSlice.actions;

export default authSlice.reducer;

import React from "react";
import Header from "../header/Header";
import cardImage from "../../assets/images/cardImage.jpg";
import cardHire from "../../assets/images/hiring-card-img.jpg";
import cardHireimg from "../../assets/images/chris-hire.jpg";
import cardHireimg2 from "../../assets/images/scott-hire.jpg";
import cardHireimg3 from "../../assets/images/clem-hiring.jpg";
import profileadd from "../../assets/images/../../assets/images/user-add-removebg-preview.png";
import invoice from "../../assets/images/../../assets/images/invoice-img-removebg-preview.png";
import messeges from "../../assets/images/../../assets/images/chat-bot-removebg-preview.png";
import hand from "../../assets/images/../../assets/images/laptop.png";


import "./Cards.scss";

function DescriptionCard() {

    return (

        <div className="decription-card">
            <Header />
            <div className="row mt-5">
                <div className="col-md-3">
                    <div className="card">
                        <img src={cardHire} alt="" className="modern-img" />
                        <div className="description">
                            <h4>Project #1</h4>
                            <h3>Modern</h3>
                            <p>As Uber works through a huge amount of internal management turmoil.</p>
                            <button className="btn btn-main">View Project</button>
                        </div>
                    </div>

                </div> <div className="col-md-3">
                    <div className="card">
                        <img src={cardHireimg} alt=""  />
                        <div className="description">
                            <h4>Project #1</h4>
                            <h3>Scandinavian</h3>
                            <p>As Uber works through a huge amount of internal management turmoil.</p>
                            <button className="btn btn-main">View Project</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <img src={cardHireimg2} alt="" />
                        <div className="description">
                            <h4>Project #1</h4>
                            <h3>Minimalist</h3>
                            <p>As Uber works through a huge amount of internal management turmoil.</p>
                            <button className="btn btn-main">View Project</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card">
                        <img src={cardHireimg3} alt="" />
                        <div className="description">
                            <h4>Project #1</h4>
                            <h3>Modern</h3>
                            <p>As Uber works through a huge amount of internal management turmoil.</p>
                            <button className="btn btn-main">View Project</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="cards-sm">
            <div className="row mt-5">
                    <div className="cards-heading pb-5">
                        <h2>Hello Developers !</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Asperiores, fugit.</p>
                    </div>
                    <div className="col-md-3">
                        <div className="user-card">
                        <h4 className="pb-1 pt-4">Members qnsite</h4>
                        <h1>26 <span className="opacity-25">/88</span></h1>
                        <div className="row d-flex">
                            <div className="col">
                            <button className="btn btn-main mt-5">View Members</button>
                            </div>
                            <div className="col user-img">
                            <img src={profileadd} alt="" className="user-profile" />
                            </div>
                        </div>
                        </div>
                    </div> 
                    <div className="col-md-3">
                        <div className="user-card">
                        <h4 className="pb-1 pt-4">Unpaid Invoices</h4>
                        <h1>35 <span className="opacity-25">/54</span></h1>
                        <div className="row d-flex">
                            <div className="col">
                            <button className="btn btn-main mt-5">View Invoices</button>
                            </div>
                            <div className="col user-img">
                            <img src={invoice} alt="" className="user-profile" />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="user-card">
                        <h4 className="pb-1 pt-4">Unread Messeges</h4>
                        <h1>10 <span className="opacity-25">/20</span></h1>
                        <div className="row d-flex">
                            <div className="col">
                            <button className="btn btn-main mt-5">View Messeges</button>
                            </div>
                            <div className="col user-img">
                            <img src={messeges} alt="" className="user-profile" />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="user-card">
                        <h4 className="pb-1 pt-4">Your Tickets</h4>
                        <h1>2 <span className="opacity-25">/18</span></h1>
                        <div className="row d-flex">
                            <div className="col">
                            <button className="btn btn-main mt-5">View Tickets</button>
                            </div>
                            <div className="col user-img">
                            <img src={hand} alt="" className="user-profile" />
                            </div>
                        </div>
                        </div>
                    </div>
                   
                   
                </div>

            </div>


        </div>
    )
}
export default DescriptionCard;